import {observable, action, runInAction, makeObservable, reaction} from 'mobx';
import axios from 'axios';
import {LOGIN} from "../graphql/Mutations";
import UserStore from "./UserStore";
import LayoutStore from "./LayoutStore";

const LANG = process.env.REACT_APP_LANG;
const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);

class AuthStore {
  token =window.localStorage.getItem('token');
  loading = false;
  errors=[];
  values = {accountNo:'', username: '', email: '', password: '',};

  constructor() {
    makeObservable(this, {
      loading: observable,
      token: observable,
      values: observable,
      errors: observable,
      login: action,
      logout: action,
      setAccountNo: action,
      setUsername: action,
      setEmail: action,
      setPassword: action,
      reset: action,
    });
    reaction(
    () => this.token,
      token => {
        if (token) {
          window.localStorage.setItem('token', token);
        } else {
          window.localStorage.removeItem('token');
        }
      }
    );
  }

  sSetToken(token) {
    runInAction(()=>{
      this.token = token;
    });
  }
  setUsername(username) {
    runInAction(() => {
      this.values.username = username;
    });
  }
  setEmail(email) {
    runInAction(() => {
      this.values.email = email;
    });
  }
  setPassword(password) {
    runInAction(() => {
      this.values.password = password;
    });
  }
  setAccountNo(accountNo) {
    runInAction(() => {
      this.values.accountNo = accountNo;
    });
  }

  reset() {
    runInAction(() => {
      this.values.accountNo = '';
      this.values.username = '';
      this.values.email = '';
      this.values.password = '';
    });
  }

  async login() {
    try{
        runInAction(()=>{
          this.loading = true;
          this.errors = [];
        });
      const {data} =  await axios({
        url:`${API_BASE}`,
        method:'post',
        data:{
          query:`mutation {${LOGIN(this.values.accountNo, this.values.email, this.values.password)}}`
        }
      });
      console.log("login data ",data);
      if(data.errors){
        runInAction(()=> {
          this.errors = data.errors;
          this.reset();
          this.loading = false;
        });
        return false;
      }
      runInAction(async () => {
        await LayoutStore.setToken(data.data.signIn.token);
        await LayoutStore.setAccountId(this.values.accountNo);
        await UserStore.pullUser();
        await UserStore.pullAccount();
        this.loading = false;
      });
      return true;
    }catch (e) {
      runInAction(()=>{
        this.loading = false;
        this.errors=[{message:e.message}];
        this.reset();
      });
      return false;
    }
  }

  logout() {
    runInAction(()=>{
      LayoutStore.setToken(undefined);
      LayoutStore.setAccountId(undefined);
    });
    return false;
  }
}
export default new AuthStore();
