import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import LayoutStore from "./LayoutStore";
import axios from "axios";
import {
    ASSIGN_USER_ACCOUNT,
    ASSIGN_USER_ROLE,
    CREATE_ACCOUNT,
    CREATE_BRAND,
    REGISTER
} from "../graphql/Mutations";
import {

} from "../graphql/Queries";
import ToasterStore from "./ToasterStore";

const LANG = process.env.REACT_APP_LANG;
const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);

class RegistrationStore {
    loading = false;
    user = null;
    brand = null;
    account = null;
    isRoleAssigned = false;
    isAccountAssigned = false;
    step = 0;
    constructor() {
        makeObservable(this, {
            loading: observable,
            user: observable,
            brand: observable,
            account: observable,
            isRoleAssigned: observable,
            isAccountAssigned: observable,
            step: observable,
            getRegistrationFromLS: action,
            register: action,
            assignRole: action,
            assignAccount: action,
            createBrand: action,
            createAccount: action,
            clearRegistration: action,
            setStep: action,
        });
    }


    async getRegistrationFromLS() {
        try {
            this.step = (parseInt(window.localStorage?.getItem('reg_step'))) ? (parseInt(window.localStorage?.getItem('reg_step'))) : (0);
            this.user = JSON.parse(window.localStorage.getItem('reg_user'));
            this.brand = JSON.parse(window.localStorage.getItem('reg_brand'));
            this.account = JSON.parse(window.localStorage.getItem('reg_account'));
            this.isRoleAssigned = Boolean(parseInt(window.localStorage.getItem('reg_role_assigned')));
            this.isAccountAssigned = Boolean(parseInt(window.localStorage.getItem('reg_account_assigned')));
        } catch (e) {
            console.log("error: ", e);
            this.loading = false;
            runInAction(() =>
                ToasterStore.pushMessage({ type: "error", body: e.message.toString() })
            );
            return false;
        }
    }

    async register(name, email, password) {

        try {
            this.loading = true;
            const { data } = await axios({
                url: `${API_BASE}`,
                method: 'post',
                data: {
                    query: `
                        mutation {${REGISTER(name, email, password)}}
                    `
                }
            });

            if (data.errors) {
                console.log(data.errors);
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                runInAction(() =>
                    ToasterStore.pushMessage({ type: "error", body: "OOPS. Something went wrong!" })
                );
                this.loading = false;
                return false;
            }
            runInAction(async () => {
                runInAction(() =>
                    ToasterStore.pushMessage({ type: "success", body: "Created user successfully" })
                );
                this.user = data.data.createUser.user;
                window.localStorage.setItem('reg_user_id', this.user.id);
                window.localStorage.setItem('reg_user', JSON.stringify(this.user));
                const newStep = this.step + 1;
                await this.setStep(newStep);
                this.loading = false;
            });
        } catch (e) {
            this.loading = false;
            runInAction(() =>
                ToasterStore.pushMessage({ type: "error", body: e.message.toString() })
            );
            return false;
        }
    }

    async assignRole(role) {
        try {
            this.loading = true;
            const accountId = LayoutStore.accountId;
            const token = LayoutStore.token;

            if (!accountId || !token) {
                return false;
            }
            const { data } = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: 'post',
                data: {
                    query: `mutation {${ASSIGN_USER_ROLE(this.user.id, role)}}`
                }
            });
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.errors = data.errors;
                this.loading = false;
                return false;
            }

            runInAction(async () => {
                this.loading = false;
                console.log("data: ", data);
                runInAction(() =>
                    ToasterStore.pushMessage({ type: "success", body: "assigned role to user successfully" })
                );
                this.isRoleAssigned = true;
                window.localStorage.setItem('reg_role_assigned', 1);
                const newStep = this.step + 1;
                await this.setStep(newStep);
            });
            this.loading = false;
        } catch (e) {
            this.loading = false;
            runInAction(() =>
                ToasterStore.pushMessage({ type: "error", body: e.message.toString() })
            );
            return false;
        }
    }

    async assignAccount(source, user, account, temporal_pass, main_color, head_color) {
        try {
            this.loading = true;
            const accountId = LayoutStore.accountId;
            const token = LayoutStore.token;
            if (!accountId || !token) {
                return false;
            }
            const { data } = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: 'post',
                data: {
                    query: `mutation {${ASSIGN_USER_ACCOUNT(source, account, user, temporal_pass, main_color, head_color)}}`
                }
            });
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.errors = data.errors;
                this.loading = false;
                return false;
            }

            runInAction(async () => {
                this.loading = false;
                console.log("data: ", data);
                runInAction(() =>

                    ToasterStore.pushMessage({ type: "success", body: "assigned account to user successfully" })

                );
                this.isAccountAssigned = true;
                window.localStorage.setItem('reg_account_assigned', 1);
                const newStep = this.step + 1;
                await this.setStep(newStep);
            });
            this.loading = false;
        } catch (e) {

            this.loading = false;
            runInAction(() =>
                ToasterStore.pushMessage({ type: "error", body: e.message.toString() })
            );
            return false;
        }
    }

    async createBrand(name, banner) {
        try {
            this.loading = true;
            const accountId = LayoutStore.accountId;
            const token = LayoutStore.token;
            if (!accountId || !token) {
                return false;
            }
            const { data } = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: 'post',
                data: {
                    query: `mutation {${CREATE_BRAND(name, banner)}}`
                }
            });
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.errors = data.errors;
                this.loading = false;
                return false;
            }

            runInAction(async () => {
                this.loading = false;
                this.brand = data.data.add_brand;
                runInAction(() =>

                    ToasterStore.pushMessage({ type: "success", body: "Created brand successfully" })
                );
                window.localStorage.setItem('reg_brand_id', this.brand.id);
                window.localStorage.setItem('reg_brand', JSON.stringify(this.brand));
                const newStep = this.step + 1;
                await this.setStep(newStep);
            });
            this.loading = false;
        } catch (e) {
            this.loading = false;
            runInAction(() =>
                ToasterStore.pushMessage({ type: "error", body: e.message.toString() })
            );
            return false;
        }
    }
    async createAccount(title, active, accept, brand, type, center, delivery, takeaway, eatin, price_group, email, kep, tax, gsm) {
        try {
            this.loading = true;
            const accountId = LayoutStore.accountId;
            const token = LayoutStore.token;
            if (!accountId || !token) {
                return false;
            }
            const { data } = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: 'post',
                data: {
                    query: `mutation {${CREATE_ACCOUNT(title, active, accept, brand, type, center, delivery, takeaway, eatin, price_group, email, kep, tax, gsm)}}`
                }
            });
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.errors = data.errors;
                this.loading = false;
                return false;
            }

            runInAction(async () => {
                this.loading = false;
                console.log("data: ", data);
                this.account = data.data.add_account;
                runInAction(() =>

                    ToasterStore.pushMessage({ type: "success", body: "Created account successfully" })
                );
                window.localStorage.setItem('reg_account_id', this.account.id);
                window.localStorage.setItem('reg_account', JSON.stringify(this.account));
                const newStep = this.step + 1;
                await this.setStep(newStep);
            });
            this.loading = false;
        } catch (e) {
            this.loading = false;
            runInAction(() =>
                ToasterStore.pushMessage({ type: "error", body: e.message.toString() })
            );
            return false;
        }
    }

    async clearRegistration() {
        try {
            this.loading = true;
            await this.setStep(0);
            window.localStorage.setItem('reg_step', 0);
            window.localStorage.removeItem('reg_user_id');
            window.localStorage.removeItem('reg_user');
            window.localStorage.removeItem('reg_role_assigned');
            window.localStorage.removeItem('reg_brand_id');
            window.localStorage.removeItem('reg_brand');
            window.localStorage.removeItem('reg_account_id');
            window.localStorage.removeItem('reg_account');
            window.localStorage.removeItem('reg_account_assigned');

            this.loading = false;
        } catch (e) {
            this.loading = false;
            runInAction(() =>
                ToasterStore.pushMessage({ type: "error", body: e.message.toString() })
            );
            console.log("error: ", e);
            return false;
        }
    }
    async setStep(step) {
        try {
            this.loading = true;
            this.step = step;
            window.localStorage.setItem('reg_step', this.step);
            this.loading = false;
        } catch (e) {
            this.loading = false;
            runInAction(() =>
                ToasterStore.pushMessage({ type: "error", body: e.message.toString() })
            );
            console.log("error: ", e);
        }
    }
}

export default new RegistrationStore();