import { action, makeObservable, observable, runInAction } from "mobx";
import LayoutStore from "./LayoutStore";
import { ACTIVE_USER, GET_ACCOUNT_DETAILS } from "../graphql/Queries";
import axios from "axios";

const LANG = process.env.REACT_APP_LANG;
const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);

class UserStore {
  currentUser = null;
  currentAccount = null;
  isCenterAccount = false;
  loading = false;
  errors = [];

  constructor() {
    runInAction(async () => {
      await this.pullUser();
      await this.pullAccount();
    });
    makeObservable(this, {
      currentUser: observable,
      currentAccount: observable,
      isCenterAccount: observable,
      loading: observable,
      errors: observable,
      pullUser: action,
      pullAccount: action,
    });
  }

  async pullUser() {
    try {
      runInAction(() => {
        this.loading = true;
        this.errors = [];
      });
      const token = LayoutStore.token;
      if (!token) {
        this.logout();
        return false;
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        headers: {
          'Authorization': token,
        },
        method: 'post',
        data: {
          query: `{${ACTIVE_USER}}`
        }
      });
      if (data.errors) {
        runInAction(() => {
          this.errors = data.errors;
          this.loading = false;
          this.logout();
        });
        return false;
      }
      runInAction(() => {
        this.currentUser = data.data.activeUser;
        this.loading = false;
      });

    } catch (e) {
      runInAction(() => {
        this.errors = [e.message];
        this.loading = false;
        this.logout();
      });
      return false;
    }
  }

  async pullAccount() {
    try {
      runInAction(() => {
        this.loading = true;
        this.errors = [];
      });
      const accountId = LayoutStore.accountId;
      const token = LayoutStore.token;
      if (!token || !accountId) {
        this.logout();
        return false;
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        headers: {
          'Authorization': token,
        },
        method: 'post',
        data: {
          query: `{${GET_ACCOUNT_DETAILS(accountId)}}`
        }
      });
      if (data.errors) {
        runInAction(() => {
          this.errors = data.errors;
          this.loading = false;
          this.logout();
        });
        return false;
      }
      runInAction(() => {
        this.currentAccount = data.data.account;
        this.isCenterAccount = (accountId === this.currentAccount.account_center)
        this.loading = false;
      });

    } catch (e) {
      runInAction(() => {
        this.errors = [e.message];
        this.loading = false;
        this.logout();
      });
      return false;
    }
  }

  logout() {
    runInAction(() => {
      LayoutStore.setToken(undefined);
      LayoutStore.setAccountId(undefined);
    });
    return false;
  }
}

export default new UserStore();
