import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import LayoutStore from "./LayoutStore";
import AsyncStorage from "@callstack/async-storage";
import ToasterStore from "./ToasterStore";
import axios from "axios";
import {
  ACCEPT_ORDER,
  ADD_ADDRESS,
  ADD_DELIVERY_CITY, ADD_KITCHEN, DELETE_KITCHEN,
  ADD_DELIVERY_NEIGHBORHOOD, ADD_NEAR_PLACE, ADD_PAYRULE, UPDATE_PAYRULE, ADD_WORK_HOURS, ADD_NEIGHBOURHOOD, REMOVE_NEIGHBOURHOOD,
  CANCEL_ORDER, UPDATE_RESTAURANT_EMAIL, UPDATE_RESTAURANT_KEP, UPDATE_RESTAURANT_TAX_NO, UPDATE_RESTAURANT_GSM,
  COMPLETE_ORDER, UPDATE_ADDRESS, UPDATE_NEIGHBOURHOOD, UPDATE_FULL_ADDRESS, UPDATE_RESTAURANT_ACTIVE,
  DELETE_DELIVERY_POINT, DELETE_NEAR_PLACE, REMOVE_PAYRULE, TOGGLE_RESTAURANT,
  UPDATE_DELIVERY_POINT, UPDATE_WORK_HOURS, UPDATE_WORK_HOURS_ALL
} from "../graphql/Mutations";
import {
  GET_ACCOUNT_DETAILS, GET_KITCHEN,
  GET_ACCOUNT_PAYRULES,
  GET_ALL_ACCEPTED_ORDERS, GET_ALL_WAITING_ORDERS, GET_ALL_DAILY_ORDERS,
  GET_NEAR_PLACES, GET_ALL_COMPLETED_ORDERS, GET_ALL_REFUSED_ORDERS,
  GET_PAYRULES,
  GET_CANCEL_REASONS, GET_LOGIN_CONFIRMATION
} from "../graphql/Queries";

const LANG = process.env.REACT_APP_LANG;
const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);

class AccountStore {
  loading = false;
  account = null;
  accountById = null;
  paymentMethods = [];
  orders = [];
  acceptedOrders = [];
  completedOrders = [];
  cancelReasons = [];
  refusedOrders = [];
  waitingOrders = [];
  dailyOrders = [];
  accountPaymentMethods = [];
  accountKitchens = [];
  nearPlaces = [];
  kitchens = [];
  loginConfirmation = [];

  constructor() {
    makeObservable(this, {
      orders: observable,
      kitchens: observable,
      accountKitchens: observable,
      cancelReasons: observable,
      waitingOrders: observable,
      dailyOrders: observable,
      loading: observable,
      refusedOrders: observable,
      completedOrders: observable,
      account: observable,
      accountById: observable,
      acceptedOrders: observable,
      paymentMethods: observable,
      accountPaymentMethods: observable,
      nearPlaces: observable,
      loginConfirmation: observable,
      getKitchens: action,
      addKitchen: action,
      deleteKitchen: action,
      getAccountPaymentMethods: action,
      deleteDP: action,
      acceptOrder: action,
      cancelOrder: action,
      completeOrder: action,
      getAccount: action,
      getAccountById: action,
      getPaymentMethods: action,
      addPaymentMethod: action,
      updatePaymentMethod: action,
      getNearPlaces: action,
      addDeliveryCity: action,
      updateDeliveryPoint: action,
      addAddress: action,
      getAcceptedOrders: action,
      addWorkHours: action,
      updateWorkHoursExplicitly: action,
      getWaitingOrders: action,
      getCompletedOrders: action,
      getAllDailyOrders: action,
      getCancelReasons: action,
      getRefusedOrders: action,
      deletePaymentMethod: action,
      addNearPlace: action,
      deleteNearPlace: action,
      addDeliveryPoint: action,
      toggleRestaurant: action,
      addNeighbourhood: action,
      updateRestaurantMail: action,
      updateRestaurantKep: action,
      updateRestaurantGsm: action,
      updateRestaurantTax: action,
      updateAddress: action,
      updateNeighbourhood: action,
      getlLoginConfirmationById: action,
    });
    this.getAccount();
  }

  async getAccount() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_ACCOUNT_DETAILS(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        //  console.log("kitchhhh: " + JSON.stringify(data.data.account))
        this.account = data.data.account;
        this.accountKitchens = data.data.account.account_kitchen;
        this.loading = false;
      });
      //    console.log("accountKitchens: " + JSON.stringify(this.accountKitchens))
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getlLoginConfirmationById(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_LOGIN_CONFIRMATION(id)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        console.log("loginConfirmation: " + JSON.stringify(data.data.account))
        //    this.loginConfirmation = data.data.account;
      });
      //    console.log("accountKitchens: " + JSON.stringify(this.accountKitchens))
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getAccountById(id) {
    console.log("id: " + id)
    try {
      runInAction(() => {
        this.loading = true;
      });
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_ACCOUNT_DETAILS(id)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        this.accountById = data.data.account;
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getKitchens() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_KITCHEN(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      // console.log("169", toJS(data.data));
      runInAction(() => {
        this.kitchens = data.data.kitchens;
        runInAction(() => { this.loading = false; });
      });
      //  console.log("kitchenskitchenskitchenskitchens" + JSON.stringify(this.kitchens.image))
    } catch (e) {
      // //console.log(e);
      // runInAction(() => {
      //   this.loading = false;
      // });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async addKitchen(kitchen_id) {
    //   console.log("kitchen_id: " + kitchen_id)
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_KITCHEN(account, kitchen_id)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "added Kitchen successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });

      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async deleteKitchen(kitchen_id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${DELETE_KITCHEN(kitchen_id)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Deleted Kitchen successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async acceptOrder(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation{${ACCEPT_ORDER(parseInt(id))}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Accepted order successfully!" });

        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async cancelOrder(id, order, index, reasonId) {
    try {
      this.loading = true;
      let accountId = await AsyncStorage.getItem('accountNo');
      const token = await AsyncStorage.getItem('token');
      if (!accountId || !token) {
        return false;
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        headers: {
          'Authorization': token,
        },
        method: 'post',
        data: {
          query: `mutation{${CANCEL_ORDER(parseInt(id), parseInt(reasonId))}}`
        }
      });
      if (data.errors) {
        //alert("OOPS. Something went wrong on: AccountStore at cancelOrder!");
        LayoutStore.enqueueSnackbar({
          message: 'OOPS. Something went wrong on: AccountStore at cancelOrder!',
          options: {
            variant: 'error',
          },
        });
        return false;
      }
      runInAction(() => {
        this.waitingOrders.splice(index, 1);
        this.acceptedOrders.splice(index, 1);
        LayoutStore.enqueueSnackbar({
          message: 'Cancelled order successfully!',
          options: {
            variant: 'default',
          },
        });
      });
      this.loading = false;
    } catch (e) {
      //console.log(e);
      this.loading = false;
      //alert("OOPS. Something went wrong on: AccountStore at cancelOrder!");
      LayoutStore.enqueueSnackbar({
        message: 'OOPS. Something went wrong on: AccountStore at cancelOrder!',
        options: {
          variant: 'error',
        },
      });
    }
  }
  async completeOrder(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation{${COMPLETE_ORDER(parseInt(id))}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Completed order successfully!" });

        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getCancelReasons(id) {
    try {
      this.loading = true;
      const accountId = await AsyncStorage.getItem('accountNo');
      const token = await AsyncStorage.getItem('token');
      ////console.log("accountId", accountId);
      if (!accountId || !token) {
        return false;
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': token,
        },
        data: {
          query: `{${GET_CANCEL_REASONS(parseInt(id))}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        runInAction(() => LayoutStore.enqueueSnackbar({
          message: 'OOPS. Something went wrong on AccountStore on getCancelReasons!',
          options: {
            variant: 'error',
          },
        }));
        return false;
      }
      this.cancelReasons = data.data.order_cancel_reasons;
    } catch (e) {
      //console.log(e);
      this.loading = false;
      runInAction(() => LayoutStore.enqueueSnackbar({
        message: 'OOPS. Something went wrong on AccountStore on getCancelReasons!',
        options: {
          variant: 'error',
        },
      }));
    }
  }
  async getPaymentMethods() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_PAYRULES()}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      this.paymentMethods = data.data.order_payrules;
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getRefusedOrders() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_ALL_REFUSED_ORDERS(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      //console.log("169", toJS(data));
      this.refusedOrders = data.data.all_refused_orders;
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getCompletedOrders() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_ALL_COMPLETED_ORDERS(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      //console.log("169", toJS(data));
      this.completedOrders = data.data.all_completed_orders;
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getAcceptedOrders() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_ALL_ACCEPTED_ORDERS(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      //console.log("169", toJS(data));
      this.acceptedOrders = data.data.all_accepted_orders;
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getAllDailyOrders() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_ALL_DAILY_ORDERS(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      //console.log("169", toJS(data));
      this.dailyOrders = data.data.all_daily_orders;
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getWaitingOrders() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_ALL_WAITING_ORDERS(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      //console.log("169", toJS(data));
      this.waitingOrders = data.data.all_waiting_orders;
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getAccountPaymentMethods() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_ACCOUNT_PAYRULES(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      this.accountPaymentMethods = data.data.account_payrules;
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async addPaymentMethod(payrule, delivery, takeaway, eatin) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_PAYRULE(account, payrule, delivery, eatin, takeaway)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "added payment methods successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updatePaymentMethod(method, payrule, delivery, takeaway, eatin) {
    console.log("payrule: " + payrule)
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_PAYRULE(account, payrule, delivery, eatin, takeaway)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: method?.orderpayrule.payrule_name + "updated successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }

  async deletePaymentMethod(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${REMOVE_PAYRULE(id)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "deleted payment method successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updateRestaurantGsm(gsm) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_RESTAURANT_GSM(account, gsm)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Restaurant Mail updated successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }

  async updateRestaurantTax(tax) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_RESTAURANT_TAX_NO(account, tax)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Restaurant Mail updated successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updateRestaurantKep(kep) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_RESTAURANT_KEP(account, kep)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Restaurant Mail updated successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updateRestaurantActive(isActive) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_RESTAURANT_ACTIVE(account, isActive)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Restaurant Mail updated successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updateRestaurantMail(email) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_RESTAURANT_EMAIL(account, email)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Restaurant Mail updated successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updateAddress(id, phone, address, direction) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_ADDRESS(id, phone, address, direction)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Updated successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updateFullAddress(id, city, town, district, neighborhood) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_FULL_ADDRESS(id, city, town, district, neighborhood)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Updated successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async getNearPlaces() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_NEAR_PLACES(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      this.nearPlaces = data.data.account_near_places;
    } catch (e) {
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async addNearPlace(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_NEAR_PLACE(account, id)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "added near place successfully" });
        this.loading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async deleteNearPlace(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${DELETE_NEAR_PLACE(account, id)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "delete near place successfully" });
        this.loading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async addDeliveryCity(id, time, minimum, price) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_DELIVERY_CITY(id, account, parseFloat(price), parseFloat(minimum), parseFloat(time))}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "added delivery point successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async addDeliveryPoint(id, time, minimum, price) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_DELIVERY_NEIGHBORHOOD(id, account, parseFloat(price), parseFloat(minimum), parseFloat(time))}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "added delivery point successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async deleteNeighbourhood(district, id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${REMOVE_NEIGHBOURHOOD(district, id)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "added delivery point successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async addNeighbourhood(district, neighborhood) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_NEIGHBOURHOOD(district, neighborhood, 11111)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "added delivery point successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updateNeighbourhood(id, district, name, zipcode) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_NEIGHBOURHOOD(id, district, name, zipcode)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Updated Neighbourhood successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updateDeliveryPoint(id, time, minimum, price) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_DELIVERY_POINT(id, account, parseFloat(price), parseFloat(minimum), parseFloat(time))}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "updated delivery point successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async deleteDP(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${DELETE_DELIVERY_POINT(id, account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "deleted delivery point successfully" });
        this.loading = false;
      });
    } catch (e) {
      //console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async addAddress(name, phone, country, city, town, district, neighborhood, address, direction, type, lat, long) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      let formData = {};
      formData['lat'] = lat;
      formData['lng'] = long;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_ADDRESS(name, phone, country, city, town, district, neighborhood, address, direction, type, account, lat, long, JSON.stringify(formData))}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "added delivery point successfully" });
        this.loading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
      });
    }
  }
  async toggleRestaurant(delivery, takeaway, eatin) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${TOGGLE_RESTAURANT(account, delivery, takeaway, eatin)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "updated restaurant status successfully" });
        this.loading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
      });
    }
  }
  async addWorkHours() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_WORK_HOURS(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "added work hours successfully" });
        this.loading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
      });
    }
  }
  async updateWorkHoursExplicitly(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_WORK_HOURS_ALL(id, account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "updated all work hours successfully" });
        this.loading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
      });
    }
  }
  async updateWorkHours(id, day, opening, closing) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_WORK_HOURS(id, account, day, opening, closing)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "added work hours successfully" });
        this.loading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
      });
    }
  }
}

export default new AccountStore();
