const app='tr';



export const APP_NAME = app==='tr'?'Yemekarena':'MOBAORDER';
export const APP_URL = app==='tr'?'http://yemekarena.com/':'http://mobaorder.com/';

//export const API_BASE = app==='tr'?'http://yemekarena.com:4001/graphql':'http://mobaorder.com:4001/graphql';
export const API_BASE = app==='tr'?'http://api-v3.fastrest.com.tr:4002/graphql':'http://mobaorder.com:4001/graphql';
export const API_WS_URL = app === "tr" ? `ws://api-v1.fastrest.com.tr:4002/graphql` : `ws://mobaorder.com:4001/graphql`;
export const CRM_API= "http://crm.fastrest.com.tr/api";
export const CDN_API= "http://cdn.fastrest.com.tr/api";
export const QR_URL = app==='tr'?'http://www.yemekarena.com:5001/menu/':'http://www.mobaorder.com/menu/';
export const CURRENCY = app==='tr'?'₺':' ';
export const EMAIL = app==='tr'?'pazarlama@begumyazilim.com.tr':'info@mobabyte.com';
export const LANGUAGE=app;
export const MAP_API_KEY="AIzaSyBun3FUlprNjXZSmtVIGDJu-Ekh_nLjWes"
