import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";
import { GET_PRODUCT, PRODUCT_TYPES } from "../graphql/Queries";
import {
  ADD_PRODUCT,
  ADD_PRODUCT_DETAIL,
  ADD_PRODUCT_MENU,
  ADD_PRODUCT_MENU_ITEM,
  ADD_PRODUCT_PRICE,
  ADD_TO_GROUP2,
  DELETE_PRODUCT_MENU,
  DELETE_PRODUCT_MENU_ITEM,
  SORT_PRODUCT,
  SORT_PRODUCT_MENU,
  SORT_PRODUCT_MENU_ITEM,
  UPDATE_PRODUCT_DETAIL,
  UPDATE_PRODUCT_IMAGE,
  UPDATE_PRODUCT_MENU_ITEM,
  UPDATE_PRODUCT_NAME,
  UPDATE_PRODUCT_PRICE
} from "../graphql/Mutations";
import ToasterStore from "./ToasterStore";
import LayoutStore from "./LayoutStore";
//import {number} from "prop-types";

const LANG = process.env.REACT_APP_LANG;
const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);

class ProductStore {
  loading = false;
  product = null;
  productTypes = [];

  constructor() {
    makeObservable(this, {
      loading: observable,
      product: observable,
      productTypes: observable,
      getProduct: action,
      getProductTypes: action,
      deleteProductMenu: action,
      deleteProductMenuItem: action,
      updateProductMenuItem: action,
      addMenuItem: action,
      sortProduct: action,
      addPrice: action,
      sortProductMenu: action,
      sortProductMenuItem: action
    });
  }

  //Product
  async getProduct(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        data: {
          query: `{${GET_PRODUCT(id, account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        alert("Error %&/");
        return false;
      }
      runInAction(() => {
        this.product = data.data.product;
        runInAction(() => {
          this.loading = false;
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      alert("Error %&/");
    }
  }
  async addProduct(group, name, type, productNumber, isActive, isMenu, imgUrl) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_PRODUCT(group, account, name, type, Number(isActive), productNumber, imgUrl, isMenu)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async sortProduct(id, sort) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${SORT_PRODUCT(id, sort)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updatedProductName(id, name) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_PRODUCT_NAME(id, name)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async setSecondaryGroup(id, group) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_TO_GROUP2(id, group)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async addPrice(id, group, delivery, takeaway, eatin) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_PRODUCT_PRICE(id, delivery, takeaway, eatin, 1, account, group)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async editPrice(id, product, delivery, takeaway, eatin, active) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_PRODUCT_PRICE(id, account, product, delivery, takeaway, eatin, active, account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async addDesc(id, type, desc) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_PRODUCT_DETAIL(id, type, desc, account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async editDesc(id, type, product, desc) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_PRODUCT_DETAIL(id, account, product, type, desc, account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async editImage(id, image) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_PRODUCT_IMAGE(id, image)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }

  //Menu
  async addProductMenu(menuType, name, price, product, sort) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_PRODUCT_MENU(menuType, name, price, product, account, sort)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Added menu  successfully" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async deleteProductMenu(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${DELETE_PRODUCT_MENU(id, account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Deleted product menu successfully" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async sortProductMenu(id, sort) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${SORT_PRODUCT_MENU(id, sort)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }

  //Menu Item
  async addMenuItem(menu, type, row, price, sort) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_PRODUCT_MENU_ITEM(menu, type, row, price, account, sort)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Added menu  successfully" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async updateProductMenuItem(id, menu, type, mainProduct, productId, price) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${UPDATE_PRODUCT_MENU_ITEM(id, account, menu, type, productId, price, account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Updated menu item successfully" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async deleteProductMenuItem(id) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${DELETE_PRODUCT_MENU_ITEM(id, account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "deleted menu item successfully" });
        this.loading = false;
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async sortProductMenuItem(id, sort) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${SORT_PRODUCT_MENU_ITEM(id, sort)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }

  //Product Types
  async getProductTypes() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        data: {
          query: `{${PRODUCT_TYPES}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching product types" });
        return false;
      }
      runInAction(() => {
        this.productTypes = data.data.product_types;
        runInAction(() => {
          this.loading = false;
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: "Error while fetching product types" });
    }
  }
}

export default new ProductStore();
