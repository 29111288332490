import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import qs from 'qs';
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import { CRM_API } from "src/constants";
import ToasterStore from "./ToasterStore";
import UserStore from "./UserStore";


class TicketStore {
    loading = false;
    crm_user = "bayramgeldi.bayriyev@gmail.com";
    crm_password = "rubin21";
    ticketDialog = false;
    active_crm_user = null;
    email = "";
    token = "";
    t_activity_sreason = 6;
    t_activityd_person = 518;
    t_activityd_detail = "Lütfen yarıdm edin 518";
    t_activity_job = 7;
    t_activity_account = 65;
    t_activity_person = 13;
    t_activity_group = 4;
    t_activity_reason = 53;
    t_activity_header = 386;
    t_activity_user = 9;
    t_activity_owner = 65;
    message = "";
    subject = "";
    subjectId = "";


    constructor() {
        makeObservable(this, {
            crm_user: observable,
            crm_password: observable,
            ticketDialog: observable,
            active_crm_user: observable,
            email: observable,
            token: observable,
            t_activity_sreason: observable,
            t_activityd_person: observable,
            t_activityd_detail: observable,
            t_activity_job: observable,
            t_activity_account: observable,
            t_activity_person: observable,
            t_activity_group: observable,
            t_activity_reason: observable,
            t_activity_header: observable,
            t_activity_user: observable,
            t_activity_owner: observable,
            message: observable,
            subject: observable,
            subjectId: observable,

            toggleDialog: action,
            suggestedTicket: action,

        });
        this.setupAuth();
    }


    async getCRMToken() {
        try {
            this.loading = true;
            const { data } = await axios({
                url: `${CRM_API}/login`,
                method: 'post',
                data: qs.stringify({
                    email: this.crm_user,
                    password: this.crm_password,
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            });
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                alert("Error1!!!")
                this.loading = false;
                return false;
            }
            runInAction(() => {
                this.loading = false;
                //alert(data.data.signIn.token)
                this.saveToken(data.result);
            })
        } catch (e) {
            this.loading = false;
            return false;
        }
    }
    async saveToken(crm_token) {
        try {
            await AsyncStorage.setItem('crm_token', crm_token);
            await this.setupAuth();

        } catch (e) {
            console.log("Hata1");
            console.log(e)
        }
    }
    async tokenControl() {
        try {
            const token = await AsyncStorage.getItem('crm_token');
            const { data } = await axios({
                url: `${CRM_API}/get_user_details`,
                method: 'post',
                data: qs.stringify({
                    token: token,
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            });

            if (!token) {
                return false;
            }
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Uzun zamandır giriş yapılmamış, tekrar giriş yapmanız gerek.");
                await this.removeToken();
                return false;
            }
            runInAction(() => {
                this.active_crm_user = data.result.id;
            });
            return token;

        } catch (e) {
            alert("I " + e);
            return false;
        }
    }
    async removeToken() {
        try {
            await AsyncStorage.removeItem('crm_token');
            this.token = null;
            await this.setupAuth();

        } catch (e) {
            console.log(e)
        }
    }

    async setupAuth() {
        const crmToken = await AsyncStorage.getItem('crm_token');
        if (!crmToken) {
            await this.getCRMToken();
        }
    }

    toggleDialog() {
        if (this.ticketDialog) {
            this.ticketDialog = false;
            this.email = UserStore.currentUser.email;
        } else {
            this.ticketDialog = true;
            this.email = UserStore.currentUser.email;
            // console.log("asas", UserStore.currentAccount.id);
        }
    }

    suggestedTicket(message, subject, subjectId) {
        runInAction(() => {
            this.ticketDialog = !this.ticketDialog;
            this.message = message;
            this.subject = subject;
            this.subjectId = subjectId;
        });
    }

    async insertActivity(message, email, subject, subjectId) {

        try {
            this.loading = true;
            const token = await AsyncStorage.getItem('crm_token');
            const username = UserStore.currentUser.name;
            //cosnt username = AuthStore.values.username
            const userid = UserStore.currentUser.id;
            const account = UserStore.currentAccount.id;
            let totalMessage = `[Subject]: ${subject}<br>
                                [E-Mail]: ${email}<br>
                                [Username]: ${username}<br>
                                [UserId]: ${userid}<br>
                                [Account Number]: ${account}<br>
                                [Message]:${message}`;
            console.log("message", token, totalMessage);
            if (!token) {
                return false;
            }

            const { data } = await axios({
                url: `${CRM_API}/insert_activity`,
                method: "post",
                data: qs.stringify({
                    token: token,
                    t_activity_sreason: this.t_activity_sreason,
                    t_activityd_person: this.t_activityd_person,
                    t_activityd_detail: `${totalMessage}`,
                    t_activity_job: this.t_activity_job,
                    t_activity_account: this.t_activity_account,
                    t_activity_person: this.t_activity_person,
                    t_activity_group: this.t_activity_group,
                    t_activity_reason: this.t_activity_reason,
                    t_activity_header: (subjectId && subjectId !== "") ? (parseInt(subjectId)) : (this.t_activity_header),
                    t_activity_user: this.t_activity_user,
                    t_activity_owner: this.t_activity_owner
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: TicketStore at insertActivity! Eror11");
                return false;
            }
            console.log("saved successfully", toJS(data));

            this.loading = false;
            runInAction(() => {
                ToasterStore.pushMessage({ type: "success", body: "Action successful" });
                this.loading = false;
            });
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: TicketStore at insertActivity!");
        }
    }

}
export default new TicketStore();