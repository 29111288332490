import {action, makeObservable, observable, runInAction} from "mobx";
import ToasterStore from "./ToasterStore";
import LayoutStore from "./LayoutStore";
import axios from "axios";
import {ADD_DOCUMENT} from "../graphql/Mutations";
import {GET_DOCUMENTS} from "../graphql/Queries";

const LANG = process.env.REACT_APP_LANG;
const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);

class DocumentStore {
  loading=false;
  documents=[];

  constructor() {
    makeObservable(this, {
      loading:observable,
      documents:observable,
      getDocuments:action,
      addDocument: action,
    });
  }

  async getDocuments() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({type: "error", body: "Error while fetching account"});
      }
      const {data} = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `{${GET_DOCUMENTS(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({type: "error", body: error.message});
        });
        return false;
      }
      this.documents=data.data.documents;
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        ToasterStore.pushMessage({type: "error", body: e.message.toString()});
      });
    }
  }
  async addDocument(url, type) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({type: "error", body: "Error while fetching account"});
      }
      const {data} = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_DOCUMENT(account, url, type)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({type: "error", body: error.message});
        });
        return false;
      }
      runInAction(()=>{
        ToasterStore.pushMessage({type: "success", body: "Added document successfully"});
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        ToasterStore.pushMessage({type: "error", body: e.message.toString()});
      });
    }
  }
}
export default new DocumentStore();
