import { action, makeObservable, observable, runInAction } from "mobx";
import LayoutStore from "./LayoutStore";
import ToasterStore from "./ToasterStore";
import axios from "axios";
import { GET_PRODUCT_PRICE_GROUPS } from "../graphql/Queries";
const LANG = process.env.REACT_APP_LANG;
const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);

class PriceGroupStore {
  loading = false;
  priceGroups = [];

  constructor() {
    makeObservable(this, {
      loading: observable,
      priceGroups: observable,
      getPriceGroups: action
    });
  }

  async getPriceGroups() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        data: {
          query: `{${GET_PRODUCT_PRICE_GROUPS(account)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching price groups" });
        return false;
      }
      runInAction(() => {
        this.priceGroups = data.data.product_price_groups;
        runInAction(() => {
          this.loading = false;
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: "Error while fetching price groups" });
    }
  }
}
export default new PriceGroupStore();
