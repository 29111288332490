import { makeObservable, observable, runInAction } from "mobx";
import LayoutStore from "./LayoutStore";
import ToasterStore from "./ToasterStore";
import axios from "axios";
import {
  GET_ACCOUNTS,
  GET_ADDRESS_TYPES, GET_BRANDS,
  GET_CITIES,
  GET_CITY, GET_BRAND,
  GET_DISTRICT,
  GET_DOCUMENT_TYPES, GET_EXTERNAL_SOURCES,
  GET_STATUSES
} from "../graphql/Queries";
const LANG = process.env.REACT_APP_LANG;
const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);

class DataStore {
  loading = false;
  cities = [];
  statuses = [];
  addressTypes = [];
  accounts = [];
  brands = [];
  brand = [];
  documentTypes = [];
  externalSources = [];
  city = null;
  district = null;
  constructor() {
    makeObservable(this, {
      loading: observable,
      cities: observable,
      statuses: observable,
      addressTypes: observable,
      accounts: observable,
      brands: observable,
      brand: observable,
      documentTypes: observable,
      externalSources: observable,
      city: observable,
      district: observable,
    });
  }


  async getCities() {
    try {
      this.loading = true;
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': `${LayoutStore.token}`,
        },
        data: {
          query: `{${GET_CITIES}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        alert("OOPS. Something went wrong on: DataStore at getCities!");
        return false;
      }
      this.cities = data.data.cities;
    } catch (e) {
      console.log(e);
      this.loading = false;
      alert("OOPS. Something went wrong on: DataStore at getCities!");
    }
  }


  async getCity(id) {
    try {
      this.loading = true;
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': `${LayoutStore.token}`,
        },
        data: {
          query: `{${GET_CITY(parseInt(id))}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        alert("OOPS. Something went wrong on: DataStore at getCity!");
        return false;
      }
      this.city = data.data.city;
    } catch (e) {
      console.log(e);
      this.loading = false;
      alert("OOPS. Something went wrong on: DataStore at getCity!");
    }
  }


  async getDistrict(id) {
    try {
      this.loading = true;
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': `${LayoutStore.token}`,
        },
        data: {
          query: `{${GET_DISTRICT(parseInt(id))}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        alert("OOPS. Something went wrong on: DataStore at getDistrict!");
        return false;
      }
      this.district = data.data.district;
    } catch (e) {
      console.log(e);
      this.loading = false;
      alert("OOPS. Something went wrong on: DataStore at getDistrict!");
    }
  }


  async getStatuses() {
    try {
      this.loading = true;
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': `${LayoutStore.token}`,
        },
        data: {
          query: `{${GET_STATUSES}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        alert("OOPS. Something went wrong on: DataStore at getStatuses!");
        return false;
      }
      this.statuses = data.data.statuses.sort(function (a, b) {
        return a.id - b.id;
      });
    } catch (e) {
      console.log(e);
      this.loading = false;
      alert("OOPS. Something went wrong on: DataStore at getStatuses!");
    }
  }


  async getAddressTypes() {
    try {
      this.loading = true;
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': `${LayoutStore.token}`,
        },
        data: {
          query: `{${GET_ADDRESS_TYPES}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        alert("OOPS. Something went wrong on: DataStore at getAddressTypes!");
        return false;
      }
      this.addressTypes = data.data.address_types;
    } catch (e) {
      console.log(e);
      this.loading = false;
      alert("OOPS. Something went wrong on: DataStore at getAddressTypes!");
    }
  }


  async getDocumentTypes() {
    try {
      this.loading = true;
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': `${LayoutStore.token}`,
        },
        data: {
          query: `{${GET_DOCUMENT_TYPES}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        runInAction(() => ToasterStore.pushMessage({ type: "error", body: "Error fetching Document Types" }));
        return false;
      }
      this.documentTypes = data.data.document_types;
    } catch (e) {
      console.log(e);
      this.loading = false;
      runInAction(() => ToasterStore.pushMessage({ type: "error", body: "Error fetching Document Types" }));
    }
  }


  async getAccounts() {
    try {
      this.loading = true;
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': `${LayoutStore.token}`,
        },
        data: {
          query: `{${GET_ACCOUNTS}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        runInAction(() => ToasterStore.pushMessage({ type: "error", body: "Error fetching Accounts" }));
        return false;
      }
      this.accounts = data.data.accounts;
    } catch (e) {
      console.log(e);
      this.loading = false;
      runInAction(() => ToasterStore.pushMessage({ type: "error", body: "Error fetching Accounts" }));
    }
  }


  async getBrand(id) {
    try {
      this.loading = true;
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': `${LayoutStore.token}`,
        },
        data: {
          query: `{${GET_BRAND(id)}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        runInAction(() => ToasterStore.pushMessage({ type: "error", body: "Error fetching Brand" }));
        return false;
      }
      this.brand = data.data.brand;
    } catch (e) {
      console.log(e);
      this.loading = false;
      runInAction(() => ToasterStore.pushMessage({ type: "error", body: "Error fetching Brand" }));
    }
  }
  async getBrands() {
    try {
      this.loading = true;
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': `${LayoutStore.token}`,
        },
        data: {
          query: `{${GET_BRANDS}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        runInAction(() => ToasterStore.pushMessage({ type: "error", body: "Error fetching Brands" }));
        return false;
      }
      this.brands = data.data.brands;
    } catch (e) {
      console.log(e);
      this.loading = false;
      runInAction(() => ToasterStore.pushMessage({ type: "error", body: "Error fetching Brands" }));
    }
  }


  async getExternalSources() {
    try {
      this.loading = true;
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          'Authorization': `${LayoutStore.token}`,
        },
        data: {
          query: `{${GET_EXTERNAL_SOURCES}}`
        }
      });
      this.loading = false;
      if (data.errors) {
        runInAction(() => ToasterStore.pushMessage({ type: "error", body: "Error fetching External Sources" }));
        return false;
      }
      this.externalSources = data.data.external_sources;
    } catch (e) {
      console.log(e);
      this.loading = false;
      runInAction(() => ToasterStore.pushMessage({ type: "error", body: "Error fetching External Sources" }));
    }
  }
}
export default new DataStore();
