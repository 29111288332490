import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";
import { GET_PRODUCT_GROUPS, GET_PRODUCT_M_GROUPS } from "../graphql/Queries";
import LayoutStore from "./LayoutStore";
import ToasterStore from "./ToasterStore";
import { ADD_PRODUCT_GROUP, SORT_PRODUCT_GROUP } from "../graphql/Mutations";

const LANG = process.env.REACT_APP_LANG;
const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);

class ProductGroupStore {
  loading = false;
  productMainGroups = [];
  productGroups = [];
  productGroupsId = "";
  products = [];
  allProducts = "";
  constructor() {
    makeObservable(this, {
      loading: observable,
      productMainGroups: observable,
      productGroups: observable,
      products: observable,
      allProducts: observable,
      setAllProducts: action,
      productGroupsId: observable,
      getProductMainGroups: action,
      setProducts: action,
      setProductGroupsId: action,
      getProductGroups: action,
      sortProductGroup: action,
      addProductGroup: action
    });
  }

  async setProducts(items) {
    runInAction(() => { this.products = items });
  }
  async setProductGroupsId(items) {
    runInAction(() => { this.productGroupsId = items });
  }

  async setData() {
    const grp = await this.getProductGroups(0);
    let allProductsInfo = [];
    let allPrices = [];

    this.productGroups.map(async group => {
      const gr = {
        all: {
          allInfo: allProductsInfo.push({
            groupNames: {
              id: group.id,
              groupName: group.product_group_main.main_group_name
            },
          }),
          products: group.products.map(async price => {
            allPrices.push({
              id: price?.id,
              name: price?.product_name,
              online_price: price.product_price?.price_value || 'yok',
              takeaway_price: price.product_price?.price_takeaway || 'yok',
              eatin_price: price.product_price?.price_eatin || 'yok',
            })
          })
        }
      }
    });
    this.setAllProducts(allPrices)
    //setAllInfo(ProductGroupStore.productGroups)
  };

  async getProductMainGroups() {
    try {
      runInAction(() => { this.loading = true; });
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        data: {
          query: `{${GET_PRODUCT_M_GROUPS}}`
        }
      });
      runInAction(() => { this.loading = false; });
      if (data.errors) {
        alert("Error %&/");
        return false;
      }
      runInAction(() => {
        this.productMainGroups = data.data.product_m_groups;
        runInAction(() => { this.loading = false; });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => { this.loading = false; });
      alert("Error %&/");
    }
  }
  async getProductGroups(sort) {
    try {
      runInAction(() => { this.loading = true; });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => { this.loading = false; });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        data: {
          query: `{${GET_PRODUCT_GROUPS(account, sort)}}`
        }
      });
      runInAction(() => { this.loading = false; });
      if (data.errors) {
        alert("Error %&/");
        return false;
      }
      runInAction(() => {
        this.productGroups = data.data.product_groups;
        runInAction(() => { this.loading = false; });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => { this.loading = false; });
      alert("Error %&/");
    }
  }
  async sortProductGroup(id, sort) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${SORT_PRODUCT_GROUP(id, sort)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async addProductGroup(group, sort, active) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const account = LayoutStore.accountId;
      if (!account) {
        runInAction(() => {
          this.loading = false;
        });
        ToasterStore.pushMessage({ type: "error", body: "Error while fetching account" });
      }
      const { data } = await axios({
        url: `${API_BASE}`,
        method: 'post',
        headers: {
          "Authorization": `${LayoutStore.token}`
        },
        data: {
          query: `mutation {${ADD_PRODUCT_GROUP(group, sort, account, active)}}`
        }
      });
      runInAction(() => {
        this.loading = false;
      });
      if (data.errors) {
        data.errors.map((error) => {
          ToasterStore.pushMessage({ type: "error", body: error.message });
        });
        return false;
      }
      runInAction(() => {
        ToasterStore.pushMessage({ type: "success", body: "Action successful" });
        this.loading = false;
        runInAction(() => {
        });
      });
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loading = false;
      });
      ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
    }
  }
  async setAllProducts(items) {
    runInAction(() => { this.allProducts = items });
  }


}

export default new ProductGroupStore();
