export const ya_logo = ['608 134', `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   viewBox="0 0 83.986664 14.786667"
   xml:space="preserve"
   id="svg2"
   version="1.1"><metadata
     id="metadata8"><rdf:RDF><cc:Work
         rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" /></cc:Work></rdf:RDF></metadata><defs
     id="defs6" /><g
     transform="matrix(1.3333333,0,0,-1.3333333,0,14.786667)"
     id="g10"><g
       transform="scale(0.1)"
       id="g12"><path
         id="path14"
         style="fill:#bd2542;fill-opacity:1;fill-rule:nonzero;stroke:none"
         d="m 308.23,91.2578 v -24.625 l 21.36,24.625 h 21.101 l -23.867,-25.6289 24.121,-41.207 h -21.109 l -12.309,22.6133 c -2.515,4.5195 -4.691,6.4453 -6.535,5.7812 -1.844,-0.6758 -2.762,-1.6797 -2.762,-3.0195 V 40.5 24.4219 h -17.585 v 86.4331 h 9.043 c 2.339,0 4.355,-0.839 6.027,-2.511 1.676,-1.676 2.515,-3.606 2.515,-5.778 V 91.2578" /><path
         id="path16"
         style="fill:#bd2542;fill-opacity:1;fill-rule:nonzero;stroke:none"
         d="m 52.9336,91.2539 c -4.3711,0 -7.0547,-2.0195 -8.0625,-6.0508 L 33.0195,43.8594 19.4141,91.2539 H 0 L 23.4414,24.4492 c 1.1758,-3.5273 0.7969,-6.0859 -1.1328,-7.6875 -1.9336,-1.5937 -4.582,-2.3945 -7.9414,-2.3945 H 9.57813 V 2.47266 L 9.57031,-0.00390625 h 3.79299 c 0.043,0 0.0976,0 0.1445,0 0.4766,0 1.0039,0 1.6172,0 0.668,0 1.2617,0 1.7617,0 6.8906,0 11.8516,1.01171625 14.875,3.02734625 3.0274,2.35547 5.5469,6.30078 7.5625,11.84766 L 65.0391,91.2539 H 52.9336" /><path
         id="path18"
         style="fill:#f8a40e;fill-opacity:1;fill-rule:nonzero;stroke:none"
         d="m 559.855,85.6016 c -6.058,3.7695 -12.695,5.6562 -19.902,5.6562 -7.047,-0.332 -13.476,-2.3789 -19.293,-6.1445 -5.812,-3.7735 -8.719,-11.2227 -8.719,-22.3633 V 36.4883 34.6016 32.7695 c 0,-2.4531 0.778,-4.4609 2.336,-6.0195 1.551,-1.5586 3.563,-2.332 6.02,-2.332 h 3.191 5.407 0.25 v 41.7773 c 0,3.9297 1.14,6.7891 3.437,8.5977 2.293,1.8008 4.918,2.7031 7.863,2.7031 2.953,0 5.614,-0.9023 7.985,-2.7031 2.375,-1.8086 3.566,-4.668 3.566,-8.5977 v -29.707 -3.7188 c 0,-2.4531 0.777,-4.4609 2.332,-6.0195 1.555,-1.5586 3.567,-2.332 6.024,-2.332 h 8.597 V 36.4883 62.75 c 0,11.4648 -3.031,19.082 -9.094,22.8516" /><path
         id="path20"
         style="fill:#f8a40e;fill-opacity:1;fill-rule:nonzero;stroke:none"
         d="m 448.09,91.2578 c -0.508,0 -0.93,0 -1.262,0 -4.519,0 -8.922,-0.5898 -13.187,-1.7578 -4.274,-1.1758 -8.004,-3.0195 -11.18,-5.5312 -3.356,-2.6836 -5.992,-6.2422 -7.918,-10.6797 -1.93,-4.4375 -2.891,-9.9219 -2.891,-16.4571 V 36.4883 34.8906 32.7695 c 0,-2.4531 0.774,-4.4609 2.336,-6.0195 1.551,-1.5586 3.563,-2.332 6.02,-2.332 h 5.472 3.125 0.637 v 34.4258 c 0,5.0273 1.172,8.707 3.52,11.0546 2.34,2.3438 5.941,3.5157 10.801,3.5157 h 1.765 l 3.922,17.8437 c -0.32,0 -0.703,0 -1.16,0" /><path
         id="path22"
         style="fill:#bd2542;fill-opacity:1;fill-rule:nonzero;stroke:none"
         d="m 90.3398,60.8516 c -4.0234,-0.961 -7.1562,-2.0078 -9.414,-3.1328 v 6.0273 c 0,4.5078 1.289,8.0469 3.8633,10.6211 2.5742,2.5742 6.1132,3.8594 10.6132,3.8594 2.7422,0 4.8247,-0.6407 6.2777,-1.9297 1.441,-1.4492 2.172,-3.3789 2.172,-5.793 0,-1.7656 -0.563,-3.5351 -1.692,-5.3047 -1.289,-1.2851 -3.3787,-2.332 -6.2733,-3.1406 z m 8.1993,-10.1328 c 6.1169,0.9687 11.1409,2.8984 15.0819,5.7929 3.945,2.8946 5.914,7.7188 5.914,14.4766 0,7.2383 -2.172,12.4258 -6.519,15.5625 -4.34,3.1406 -9.411,4.707 -15.1996,4.707 -2.8945,0 -6.2305,-0.207 -10.0117,-0.6055 -3.7774,-0.4062 -7.3555,-1.4843 -10.7344,-3.2578 -3.2187,-1.6054 -5.9922,-4.2226 -8.3281,-7.8398 -2.3281,-3.6211 -3.5,-8.6484 -3.5,-15.0781 v -21.961 c 0,-5.789 2.3359,-10.2929 6.9961,-13.5078 4.5078,-3.0586 11.2656,-4.5859 20.2695,-4.5859 8.0472,0 14.2382,1.8515 18.5782,5.5469 2.844,2.371 4.805,5.2187 5.902,8.5429 l -15.511,3.4102 c -0.809,-1.5625 -2.0161,-2.668 -3.6606,-3.2656 -1.7695,-0.6407 -3.7734,-0.9649 -6.0312,-0.9649 -2.0899,0 -4.3789,0.3633 -6.875,1.0859 -2.4961,0.7266 -3.7383,2.293 -3.7383,4.7071 0,3.2187 3.539,5.3086 10.6133,6.2695 0.6562,0.0742 1.2695,0.1563 1.8593,0.2422 v 0 l 4.8946,0.7227" /><path
         id="path24"
         style="fill:#bd2542;fill-opacity:1;fill-rule:nonzero;stroke:none"
         d="m 254.738,60.8516 c -4.015,-0.961 -7.156,-2.0078 -9.41,-3.1328 v 6.0273 c 0,4.5078 1.289,8.0469 3.86,10.6211 2.574,2.5742 6.113,3.8594 10.617,3.8594 2.738,0 4.828,-0.6407 6.273,-1.9297 1.449,-1.4492 2.172,-3.3789 2.172,-5.793 0,-1.7656 -0.562,-3.5351 -1.687,-5.3047 -1.286,-1.2851 -3.379,-2.332 -6.278,-3.1406 z m 8.203,-10.1328 c 6.114,0.9687 11.137,2.8984 15.079,5.7929 3.945,2.8946 5.914,7.7188 5.914,14.4766 0,7.2383 -2.172,12.4258 -6.52,15.5625 -4.34,3.1406 -9.406,4.707 -15.199,4.707 -2.895,0 -6.231,-0.207 -10.012,-0.6055 -3.777,-0.4062 -7.355,-1.4843 -10.734,-3.2578 -3.219,-1.6054 -5.992,-4.2226 -8.324,-7.8398 -2.332,-3.6211 -3.504,-8.6484 -3.504,-15.0781 v -21.961 c 0,-5.789 2.336,-10.2929 7.004,-13.5078 4.5,-3.0586 11.257,-4.5859 20.261,-4.5859 8.047,0 14.239,1.8515 18.582,5.5469 2.84,2.371 4.805,5.2187 5.903,8.5429 l -15.512,3.4102 c -0.813,-1.5625 -2.02,-2.668 -3.664,-3.2656 -1.77,-0.6407 -3.774,-0.9649 -6.031,-0.9649 -2.09,0 -4.379,0.3633 -6.875,1.0859 -2.493,0.7266 -3.739,2.293 -3.739,4.7071 0,3.2187 3.539,5.3086 10.614,6.2695 0.656,0.0742 1.277,0.1563 1.863,0.2422 v 0 l 4.894,0.7227" /><path
         id="path26"
         style="fill:#f8a40e;fill-opacity:1;fill-rule:nonzero;stroke:none"
         d="m 476.391,60.8516 c -4.02,-0.961 -7.161,-2.0078 -9.411,-3.1328 v 6.0273 c 0,4.5078 1.29,8.0469 3.86,10.6211 2.574,2.5742 6.117,3.8594 10.617,3.8594 2.734,0 4.824,-0.6407 6.27,-1.9297 1.453,-1.4492 2.175,-3.3789 2.175,-5.793 0,-1.7656 -0.562,-3.5351 -1.691,-5.3047 -1.285,-1.2851 -3.379,-2.332 -6.273,-3.1406 z m 8.203,-10.1328 c 6.113,0.9687 11.136,2.8984 15.078,5.7929 3.945,2.8946 5.914,7.7188 5.914,14.4766 0,7.2383 -2.176,12.4258 -6.516,15.5625 -4.343,3.1406 -9.41,4.707 -15.199,4.707 -2.894,0 -6.23,-0.207 -10.016,-0.6055 -3.773,-0.4062 -7.359,-1.4843 -10.738,-3.2578 -3.215,-1.6054 -5.988,-4.2226 -8.324,-7.8398 -2.328,-3.6211 -3.496,-8.6484 -3.496,-15.0781 v -21.961 c 0,-5.789 2.336,-10.2929 6.996,-13.5078 4.504,-3.0586 11.262,-4.5859 20.27,-4.5859 8.042,0 14.234,1.8515 18.578,5.5469 2.839,2.371 4.8,5.2187 5.898,8.5429 l -15.512,3.4102 c -0.808,-1.5625 -2.015,-2.668 -3.656,-3.2656 -1.769,-0.6407 -3.781,-0.9649 -6.035,-0.9649 -2.086,0 -4.383,0.3633 -6.875,1.0859 -2.492,0.7266 -3.742,2.293 -3.742,4.7071 0,3.2187 3.543,5.3086 10.617,6.2695 0.652,0.0742 1.273,0.1563 1.863,0.2422 h -0.004 l 4.899,0.7227" /><path
         id="path28"
         style="fill:#f8a40e;fill-opacity:1;fill-rule:nonzero;stroke:none"
         d="m 379.828,54.8203 c 4.024,0.9688 7.156,2.0117 9.414,3.1367 v -6.0312 c 0,-4.5078 -1.289,-8.0469 -3.863,-10.6133 -2.574,-2.5742 -6.113,-3.8633 -10.613,-3.8633 -2.743,0 -4.825,0.6406 -6.278,1.9297 -1.445,1.4492 -2.172,3.3789 -2.172,5.793 0,1.7695 0.563,3.5312 1.692,5.3086 1.289,1.2851 3.379,2.3281 6.273,3.1367 z m -8.199,10.1367 c -6.117,-0.9648 -11.141,-2.8984 -15.082,-5.7929 -3.945,-2.8907 -5.914,-7.7227 -5.914,-14.4727 0,-7.2422 2.172,-12.4297 6.519,-15.5664 4.34,-3.1367 9.411,-4.7031 15.2,-4.7031 2.894,0 6.23,0.2031 10.011,0.6015 3.778,0.4024 7.356,1.4883 10.735,3.2618 3.218,1.6054 5.992,4.2187 8.328,7.8359 2.328,3.6211 3.5,8.6484 3.5,15.082 v 21.9571 c 0,5.7929 -2.336,10.289 -6.996,13.5078 -4.508,3.0547 -11.266,4.5898 -20.27,4.5898 -8.047,0 -14.238,-1.8555 -18.582,-5.5508 -2.84,-2.3711 -4.801,-5.2226 -5.902,-8.5429 l 15.515,-3.4102 c 0.809,1.5664 2.016,2.668 3.661,3.2656 1.765,0.6407 3.773,0.9649 6.031,0.9649 2.09,0 4.379,-0.3594 6.875,-1.0821 2.496,-0.7265 3.738,-2.2968 3.738,-4.707 0,-3.2226 -3.539,-5.3086 -10.613,-6.2734 -0.656,-0.0742 -1.27,-0.1563 -1.86,-0.2461 v 0 l -4.894,-0.7188" /><path
         id="path30"
         style="fill:#f8a40e;fill-opacity:1;fill-rule:nonzero;stroke:none"
         d="m 604.781,54.8203 c 4.02,0.9688 7.16,2.0117 9.41,3.1367 v -6.0312 c 0,-4.5078 -1.289,-8.0469 -3.855,-10.6133 -2.574,-2.5742 -6.117,-3.8633 -10.617,-3.8633 -2.739,0 -4.828,0.6406 -6.274,1.9297 -1.449,1.4492 -2.172,3.3789 -2.172,5.793 0,1.7695 0.563,3.5312 1.688,5.3086 1.285,1.2851 3.379,2.3281 6.277,3.1367 z m -8.203,10.1367 c -6.109,-0.9648 -11.137,-2.8984 -15.074,-5.7929 -3.945,-2.8907 -5.914,-7.7227 -5.914,-14.4727 0,-7.2422 2.172,-12.4297 6.512,-15.5664 4.347,-3.1367 9.414,-4.7031 15.203,-4.7031 2.894,0 6.234,0.2031 10.011,0.6015 3.782,0.4024 7.36,1.4883 10.739,3.2618 3.218,1.6054 5.992,4.2187 8.324,7.8359 2.332,3.6211 3.496,8.6484 3.496,15.082 v 21.9571 c 0,5.7929 -2.332,10.289 -6.996,13.5078 -4.5,3.0547 -11.262,4.5898 -20.27,4.5898 -8.039,0 -14.23,-1.8555 -18.574,-5.5508 -2.84,-2.3711 -4.805,-5.2226 -5.902,-8.5429 l 15.512,-3.4102 c 0.812,1.5664 2.015,2.668 3.66,3.2656 1.769,0.6407 3.777,0.9649 6.035,0.9649 2.09,0 4.379,-0.3594 6.875,-1.0821 2.488,-0.7265 3.738,-2.2968 3.738,-4.707 0,-3.2226 -3.539,-5.3086 -10.613,-6.2734 -0.656,-0.0742 -1.277,-0.1563 -1.863,-0.2461 v 0.0039 l -4.899,-0.7227" /><path
         id="path32"
         style="fill:#bd2542;fill-opacity:1;fill-rule:nonzero;stroke:none"
         d="m 134.25,24.3477 h 8.844 v 41.7578 c 0,3.9297 1.148,6.7968 3.441,8.5976 2.289,1.8047 4.914,2.7032 7.863,2.7032 2.946,0 5.606,-0.8985 7.977,-2.7032 2.375,-1.8008 3.566,-4.6679 3.566,-8.5976 v -3.4375 -19.8594 0 -8.5742 -1.5313 c 0,-2.457 0.778,-4.4687 2.332,-6.0195 1.555,-1.5586 3.563,-2.3359 6.016,-2.3359 h 8.602 v 9.8867 0 28.4336 3.4375 c 0,3.9297 1.183,6.7968 3.558,8.5976 2.371,1.8047 5.039,2.7032 7.985,2.7032 2.949,0 5.609,-0.8985 7.98,-2.7032 2.375,-1.8008 3.566,-4.6679 3.566,-8.5976 V 32.7031 c 0,-2.457 0.778,-4.4687 2.333,-6.0195 1.554,-1.5586 3.558,-2.3359 6.015,-2.3359 h 8.602 V 62.668 c 0,11.4609 -3.035,19.0742 -9.09,22.8437 -6.063,3.7617 -12.695,5.6485 -19.895,5.6485 -3.605,-0.1602 -7.086,-0.6993 -10.441,-1.5977 -3.359,-0.8984 -6.266,-2.418 -8.723,-4.5391 -2.945,2.4532 -6.222,4.0899 -9.82,4.9102 -3.609,0.8164 -7.293,1.2266 -11.059,1.2266 -7.043,-0.3282 -13.468,-2.375 -19.281,-6.1368 -5.812,-3.7695 -8.719,-11.2226 -8.719,-22.3554 V 42.8086 34.2344 32.7031 c 0,-2.457 0.778,-4.4687 2.332,-6.0195 1.555,-1.5586 3.563,-2.3359 6.016,-2.3359" /></g></g></svg>`]
