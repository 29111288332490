import { action, makeObservable, observable, runInAction } from "mobx";

class LayoutStore {
  showSidebar = false;
  language = "tr";
  token = window.localStorage.getItem('token');
  accountId = window.localStorage.getItem('accountId');

  constructor() {
    makeObservable(this, {
      showSidebar: observable,
      language: observable,
      token: observable,
      toggle: action,
    })
  }

  setToken(token) {
    runInAction(() => {
      this.token = token;
      if (this.token) {
        window.localStorage.setItem('token', this.token);
      } else {
        window.localStorage.removeItem('token');
      }
    });
  }
  setAccountId(accountId) {
    runInAction(() => {
      this.accountId = accountId;
      if (this.token) {
        window.localStorage.setItem('accountId', this.accountId);
      } else {
        window.localStorage.removeItem('accountId');
      }
    });
  }
  toggle() {
    this.showSidebar = !this.showSidebar
  }


  enqueueSnackbar(note) {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      ...note,
    });
  }
}
export default new LayoutStore();
