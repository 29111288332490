import { action, makeObservable, observable, runInAction } from "mobx";
import LayoutStore from "./LayoutStore";
import ToasterStore from "./ToasterStore";
import axios from "axios";
import { GET_CUMULATIVE_CREDIT, GET_DAILY_CREDIT, CREDITS } from "../graphql/Queries";
const LANG = process.env.REACT_APP_LANG;
const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);

class CreditStore {
    loading = false;
    cumulativeCredit = null;
    dailyCreditLog = [];
    creditLog = [];



    constructor() {
        makeObservable(this, {
            loading: observable,
            cumulativeCredit: observable,
            dailyCreditLog: observable,
            creditLog: observable,
            getCumulativeCredit: action,
            getDailyCredits: action,
            getCredits: action,
        });
    }


    async getCumulativeCredit() {
        try {
            this.loading = true;
            const accountId = LayoutStore.accountId;
            if (!accountId) {
                this.loading = false;
                console.log("return-false")
                return false;
            }
            const { data } = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': `${LayoutStore.token}`,
                },
                data: {
                    query: `{${GET_CUMULATIVE_CREDIT(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => {
                    this.loading = false;
                    runInAction(() => {
                        ToasterStore.pushMessage({ type: "error", body: "OOPS. Something went wrong on: CreditStore at getCumulativeCredit!" });
                        this.loading = false;
                    });
                });
                return false;
            }
            this.cumulativeCredit = data.data.cumulative_credit_log;
        } catch (e) {
            runInAction(() => {
                this.loading = false;
                runInAction(() => {
                    ToasterStore.pushMessage({ type: "error", body: "OOPS. Something went wrong on: CreditStore at getCumulativeCredit!" });
                    this.loading = false;
                });
            });
        }
    }

    async getDailyCredits() {
        try {
            this.loading = true;
            const accountId = LayoutStore.accountId;
            if (!accountId) {
                return false;
            }
            const { data } = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': `${LayoutStore.token}`,
                },
                data: {
                    query: `{${GET_DAILY_CREDIT(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => {
                    this.loading = false;
                    runInAction(() => {
                        ToasterStore.pushMessage({ type: "error", body: "OOPS. Something went wrong on: CreditStore at getDailyCredits!" });
                        this.loading = false;
                    });
                });
                return false;
            }
            this.dailyCreditLog = data.data.daily_credit_log;
        } catch (e) {
            runInAction(() => {
                this.loading = false;
                runInAction(() => {
                    ToasterStore.pushMessage({ type: "error", body: "OOPS. Something went wrong on: CreditStore at getDailyCredits!" });
                    this.loading = false;
                });
            });
        }
    }

    async getCredits(start_date, end_date) {
        console.log("start_date: " + start_date);
        try {
            this.loading = true;
            const accountId = LayoutStore.accountId;
            if (!accountId) {
                return false;
            }
            const { data } = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': `${LayoutStore.token}`,
                },
                data: {
                    query: `{${CREDITS(parseInt(accountId), start_date, end_date)}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => {
                    this.loading = false;
                    runInAction(() => {
                        ToasterStore.pushMessage({ type: "error", body: "OOPS. Something went wrong on: CreditStore at getCredits!" });
                        this.loading = false;
                    });
                });
                return false;
            }
            this.creditLog = data.data.credit_by_date;
        } catch (e) {
            runInAction(() => {
                this.loading = false;
                runInAction(() => {
                    ToasterStore.pushMessage({ type: "error", body: "OOPS. Something went wrong on: CreditStore at getCredits!" });
                    this.loading = false;
                });
            });
        }
    }
}

export default new CreditStore();