import { action, makeObservable, observable, runInAction } from "mobx";
import ToasterStore from "./ToasterStore";

//PRICE GROUPS MATCHING VALUES FROM LOCALSTRORAGE
let prGrMatching = JSON.parse(localStorage.getItem("prGrMatching"))

class ExcelStore {




    myProducts = [
        {
            "URUN_ADI": "APEROL SPRİTZ",
            "KDV": 8,
            "GRUP1": "ALKOLLÜ KOKTEYLER",
        },
        {
            "URUN_ADI": "COSMOPOLİTAN",
            "KDV": 28,
            "GRUP1": "ALKOLLÜ KOKTEYLER",
        },
        {
            "URUN_ADI": "BEST VANİLLA",
            "KDV": 10,
            "GRUP1": "ANAYEMEK TAVUKLAR",
        },
        {
            "URUN_ADI": "BİTTER PASSİON SOUR",
            "KDV": 18,
            "GRUP1": "AROMALI ÇAYLAR",
        },
        {
            "URUN_ADI": "CİN TONİK",
            "KDV": 3,
            "GRUP1": "ANA YEMEK BALIKLAR",
        }
    ];
    loading = false;
    excelFile = null;
    excelFileError = null;
    urunAdi = "";
    kdv = "";
    grup1 = "";
    groupId = 0;
    productType = "";
    open = false;
    hasChosed = false;
    loadedData = [true];
    excelData = null;
    isSync = false;
    pageNo = 0;
    statu = false;
    prGrMatching = prGrMatching;



    constructor() {
        makeObservable(this, {
            loading: observable,
            myProducts: observable,
            excelFile: observable,
            excelFileError: observable,
            urunAdi: observable,
            kdv: observable,
            grup1: observable,
            open: observable,
            hasChosed: observable,
            loadedData: observable,
            excelData: observable,
            isSync: observable,
            statu: observable,
            pageNo: observable,
            prGrMatching: observable,
            productType: observable,
            groupId: observable,
            setGroupId: action,
            setProductType: action,
            setPrGrMatching: action,
            setPageNo: action,
            setExcelFile: action,
            setExcelFileError: action,
            setUrunAdi: action,
            setKdv: action,
            setGrup1: action,
            setHasChosed: action,
            setOpen: action,
            setLoadedData: action,
            setExcelData: action,
            getAllLoadedData: action,
            addAllData: action,
            sendAllDataToApi: action,
            setIsSync: action,
            setStatu: action,
        });
        this.getAllLoadedData();
    }


    async getAllLoadedData() {
        // console.log("tekrarladı")
        const mappedData = JSON.parse(localStorage.getItem("mappedData"))
        this.setLoadedData(mappedData);
    }
    async addAllData(data) {
        this.getAllLoadedData()
        let isDataExist = false;
        console.log("first: " + this.loadedData)
        this.loadedData.map(loadedD => {
            if (loadedD.name == this.urunAdi && loadedD.kdv == this.kdv && loadedD.g1 == this.grup1) {
                console.log("first")
                this.setIsSync(false);
                ToasterStore.pushMessage({ type: "error", body: "Bu ürün zaten eşleştirilmiş!" });
                isDataExist = true;
            }
        })
        if (isDataExist == true) {
            return false;
        }
        else {
            if (this.urunAdi.length == 0 || this.kdv.length == 0 || this.grup1.length == 0) {
                return false;
            } else {
                this.getAllLoadedData()
                //sd
                fetch(
                    'https://temporarydata-3dc41-default-rtdb.firebaseio.com/allData.json',
                    {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                ToasterStore.pushMessage({ type: "sucess", body: this.urunAdi + ", " + this.kdv + ", " + this.grup1 + " Başarıyla eşleştirildi" });
                this.setUrunAdi("")
                this.setKdv("")
                this.setGrup1("")
                this.setIsSync(true);
                // IndividualExcelData.setSsetIsSync(true)
            }
        }
    }
    async sendAllDataToApi() {
        this.loadedData.map(data => {
            //console.log(data)
            fetch(
                'https://tempdata-d812a-default-rtdb.firebaseio.com/allData.json',
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            ToasterStore.pushMessage({ type: "sucess", body: data.name + ", " + data.kdv + ", " + data.g1 + "Tüm Eşleştirmeler Başarıyla Kaydedildi" });

        })
    }
    async getPrGrMatching() {
        let prGrMatching = JSON.parse(localStorage.getItem("prGrMatching"))
        this.setPrGrMatching(prGrMatching);
    }
    async setPrGrMatching(items) {
        runInAction(() => { this.prGrMatching = items });
    }
    async setExcelFile(items) {
        runInAction(() => { this.excelFile = items });
    }
    async setExcelFileError(items) {
        runInAction(() => { this.excelFileError = items });
    }
    async setUrunAdi(items) {
        runInAction(() => { this.urunAdi = items });
    }
    async setKdv(items) {
        runInAction(() => { this.kdv = items });
    }
    async setExcelFile(items) {
        runInAction(() => { this.excelFile = items });
    }
    async setGrup1(items) {
        runInAction(() => { this.grup1 = items });
    }
    async setHasChosed(items) {
        runInAction(() => { this.hasChosed = items });
    }
    async setOpen(items) {
        runInAction(() => { this.open = items });
    }
    async setLoadedData(items) {
        runInAction(() => { this.loadedData = items });
    }
    async setExcelData(items) {
        runInAction(() => { this.excelData = items });
    }
    async setIsSync(items) {
        runInAction(() => { this.isSync = items });
    }
    async setStatu(items) {
        runInAction(() => { this.statu = items });
    }
    async setPageNo(items) {
        runInAction(() => { this.pageNo = items });
    }
    async setProductType(items) {
        runInAction(() => { this.productType = items });
    }
    async setGroupId(items) {
        runInAction(() => { this.groupId = items });
    }
}

export default new ExcelStore();