import { action, makeObservable, observable, runInAction } from "mobx";
import ToasterStore from "./ToasterStore";
import axios from "axios";

//const LANG = process.env.REACT_APP_LANG;
//const API_BASE = (LANG === 'tr') ? (process.env.REACT_APP_API_BASE_TR) : (process.env.REACT_APP_API_BASE_EN);
const CDN_BASE_URL = process.env.REACT_APP_CDN;

class CDNStore {
  email = "bayramgeldi.bayriyev@gmail.com";
  pass = "rubin21";
  loading = false;
  modal = false;
  url = "";
  cdnToken = "";

  constructor() {
    makeObservable(this, {
      loading: observable,
      modal: observable,
      url: observable,
      uploadImage: action,
      openModal: action,
      closeModal: action
    });
    if (!window.localStorage.getItem('cdn-token') || window.localStorage.getItem('cdn-token').length < 17) {
      this.getToken();
    } else {
      this.cdnToken = window.localStorage.getItem('cdn-token');
    }
  }

  openModal() {
    runInAction(() => {
      this.url = "";
      this.modal = true;
    });
  }

  closeModal() {
    runInAction(() => {
      this.modal = false;
    });
  }
  resetUrl() {
    runInAction(() => {
      this.url = "";
    });
  }
  async getToken() {
    try {
      this.loading = true;
      let FormData = require('form-data');
      let datum = new FormData();
      datum.append('email', this.email);
      datum.append('password', this.pass);
      const { data } = await axios({
        url: `${CDN_BASE_URL}/login`,
        method: 'post',
        data: datum
      });

      console.log("res ", data);
      runInAction(() => {
        this.loading = false;
        window.localStorage.setItem("cdn-token", data.access_token);
        this.cdnToken = data.access_token;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        console.log("eee", e);
        ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
      });
    }
  }

  async uploadImage(image) {
    try {
      this.loading = true;
      if (!this.cdnToken || this.cdnToken.length < 17) {
        return false;
      }
      let datum = new FormData();
      datum.append('image', image);
      const { data } = await axios({
        url: `${CDN_BASE_URL}/upload`,
        method: 'post',
        data: datum,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${this.cdnToken}`,
        }
      });

      runInAction(() => {
        this.loading = false;
        ToasterStore.pushMessage({ type: "success", body: data.message });
        this.url = data.data.src;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        console.log("eee", e);
        ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
      });
    }
  }

  async uploadFile(file) {
    try {
      this.loading = true;
      if (!this.cdnToken || this.cdnToken.length < 17) {
        return false;
      }
      let datum = new FormData();
      datum.append('file', file);
      const { data } = await axios({
        url: `${CDN_BASE_URL}/pdf/upload`,
        method: 'post',
        data: datum,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${this.cdnToken}`,
        }
      });

      runInAction(() => {
        this.loading = false;
        ToasterStore.pushMessage({ type: "success", body: data.message });
        this.url = data.data.src;
      });
    } catch (e) {
      runInAction(() => {
        this.loading = false;
        ToasterStore.pushMessage({ type: "error", body: e.message.toString() });
      });
    }
  }
}

export default new CDNStore();
