export const LOGIN = (accountNo, email, password) => `signIn(accountNo:${accountNo} email: "${email}", password: "${password}") {
        token
        user {
          id
          name
          email
          user_role{id role{id name display_name}}
          }
          }`;
export const REGISTER = (name, email, password) => `createUser(name:"${name}" email:"${email}" password:"${password}"){
  user{
    id
    name
    email
  }
}`;
export const FORGET_PASSWORD = (email) => `forgetPass(email:"${email}"){success message}`;
export const UPDATE_PASSWORD = (email, token, password) => `forgetPassUpdate(email:"${email}" reset_token:"${token}" new_password:"${password}"){
  success message}`;

export const CREATE_BRAND = (name, banner) => `
add_brand(brand_name:"${name}", brand_banner:"${banner}"){
  id
  brand_name
  brand_banner
}`;
export const CREATE_ACCOUNT = (title, active, accept, brand, type, center, delivery, takeaway, eatin, price_group, email, kep, tax, gsm) => `add_account(
  account_active: ${active}
  account_order_accept: ${accept}
  account_title:"${title}"
  account_brand: ${brand}
  account_type: ${type}
  account_center: ${center}
  account_delivery:${delivery}
  account_takeaway: ${takeaway}
  account_eatin: ${eatin}
  account_price_group:${price_group}
  account_e_mail: "${email}"
  account_kep: "${kep}"
  account_tax_no: "${tax}"
  account_gsm_no: "${gsm}"
){
  id
  account_title
  account_active
  account_order_accept
  account_type
  account_center
  account_delivery
  account_takeaway
  account_eatin
  account_price_group
  account_e_mail
  account_kep
  account_tax_no
  account_gsm_no
  brand{
    id
    brand_name
    brand_banner
  }
}`;
export const ASSIGN_USER_ROLE = (user, role) => `add_user_role(user_id:${user} role:${role}){
  user_id
  role{
    id
    name
    display_name
    description
  }
}`;
export const ASSIGN_USER_ACCOUNT = (source, account, user, temporal_pass, main_color, head_color) => `add_external_source_account(external_source:${source}, account:${account}, user:${user} temporal_pass:"${temporal_pass}", main_color:"${main_color}", head_color:"${head_color}"){
  id
  account
  main_color
  head_color
  external_source
}`;

//Active Groups
export const ADD_ACTIVE_GROUP = (name, account, active, start, end) => `
add_active_group(active_name:"${name}", active_account:${account}, active_true:${active},active_start_time:"${start}",active_end_time:"${end}"){
  id
  active_name
  active_true
  active_start_time
  active_end_time
}`;
export const UPDATE_ACTIVE_GROUP = (id, name, account, start, end) => `
update_active_group(id:${id},active_name:"${name}", active_account:${account}, active_start_time:"${start}",active_end_time:"${end}"){
  id
  active_name
  active_true
  active_start_time
  active_end_time
}`;
export const TOGGLE_ACTIVE_GROUP = (id, status) => `update_active_group(id:${id}, active_true:${status}){
  id
  active_name
  active_true
  active_start_time
  active_end_time
}`;

//Kitchens
export const ADD_KITCHEN = (kitchen_account, kitchen_id) => `add_account_kitchen(kitchen_account: ${kitchen_account},kitchen_id:${kitchen_id}){
    id
    kitchen_id {
        id
        name
        image
    }
} `;
export const UPDATE_KITCHEN = (id, kitchen_account, kitchen_id) => `update_account_kitchen(Id: ${id},kitchen_account: ${kitchen_account},kitchen_id:${kitchen_id}){
    id
    kitchen_id {
        id
        name
        image
    }
} `;
export const DELETE_KITCHEN = (kitchen_id) => `delete_account_kitchen(id:${kitchen_id}){
    success
    message
} `;

//Product
export const ADD_PRODUCT = (product_group, product_account, product_name, product_type, product_active, product_number, img_url, ismenu) => `
add_product (product_group: ${product_group}, product_account: ${product_account}, product_name: "${product_name}", product_type: ${product_type}, product_active: ${product_active}, product_number: ${product_number}, product_is_menu:${ismenu})
    {
        id
        product_name
        product_is_menu
        account_product_name {
            id
            except_p_account
            except_p_row
            except_p_type
            except_p_data
            created_at
            updated_at
        }
        product_price {
            id
            price_value
            price_takeaway
            price_eatin
            price_active
        }
        account_product_price {
            id
            except_c_account
            except_c_row
            except_c_value
            except_c_takeaway
            except_c_eatin
            created_at
            updated_at
        }
        product_detail {
            id
            detail_type
            detail_text
        }
        product_menu {
            id
            menu_type
            menu_name
            menu_price
        }
        product_number
        img_url
    }`;
export const UPDATE_PRODUCT_NAME = (id, name) => `update_product(id:${id} product_name:"${name}"){id product_name}`;
export const UPDATE_PRODUCT = (id, account, group, pAccount, name, type, active, number, img_url, ismenu) => `
update_product (id: ${id}, account: ${account}, product_group: ${group}, product_account: ${pAccount}, product_name: "${name}", product_type: ${type}, product_active: ${active}, product_number: ${number}, img_url: "${img_url}", product_is_menu:${ismenu}) {
        id
        product_name
        account_product_name {
            id
            except_p_account
            except_p_row
            except_p_type
            except_p_data
            created_at
            updated_at
        }
        product_price {
            id
            price_value
            price_takeaway
            price_eatin
            price_active
        }
        account_product_price {
            id
            except_c_account
            except_c_row
            except_c_value
            except_c_takeaway
            except_c_eatin
            created_at
            updated_at
        }
        product_detail {
            id
            detail_type
            detail_text
        }
        product_menu {
            id
            menu_type
            menu_name
            menu_price
        }
        product_number
        img_url
    }`;
export const SORT_PRODUCT = (id, product_sort) => `update_product(id:${id} product_sort:${product_sort}){id product_sort}`;
export const ADD_TO_GROUP2 = (id, group2) => `update_product(id:${id} product_group2:${group2}){
  id
  product_name
  product_group{
    id
    product_group_main{id main_group_name}
  }
  product_group2{
    id
    product_group_main{id main_group_name}
  }
}`;
export const UPDATE_PRODUCT_IMAGE = (id, image) => `update_product(id:${id} img_url:"${image}"){
  id
  product_name
  img_url
}`;
//Product Group
export const ADD_PRODUCT_GROUP = (group_main, group_sort, group_account, group_active, groupExt = -1) => `add_group (group_main: ${group_main}, group_sort: ${group_sort}, group_account: ${group_account}, group_active: ${group_active}, group_external:${groupExt}) {
       id
        product_group_main {
            id
            main_group_name
            account_group_name {
                id
                except_g_account
                except_g_row
                except_g_type
                except_g_data
                created_at
                updated_at
            }
            img_url
        }
        group_sort
        group_active {
            id
            active_name
            active_true
            active_start_time
            active_end_time
            created_at
            updated_at
        }
    }`;
export const UPDATE_PRODUCT_GROUP = (id, account, mainGroup, sort, gAccount, active, groupExt = -1) => `
    update_group (id: ${id}, account: ${account}, product_group_main: ${mainGroup}, group_sort: ${sort}, group_account: ${gAccount}, group_active: ${active}, group_external:${groupExt}) {
        id
        product_group_main {
            id
            main_group_name
            account_group_name {
                id
                except_g_account
                except_g_row
                except_g_type
                except_g_data
                created_at
                updated_at
            }
            img_url
        }
        products {
            id
            product_name
            product_number
            img_url
        }
        group_sort
        group_active {
            id
            active_name
            active_true
            active_start_time
            active_end_time
            created_at
            updated_at
        }
    }`;
export const SORT_PRODUCT_GROUP = (id, sort) => `update_group(id:${id} group_sort:${sort}){id}`;
//Product Price
export const ADD_PRODUCT_PRICE = (product, value, takeaway, eatin, active, account, group) => `
    add_product_price (price_product: ${product}, price_value: ${value}, price_takeaway: ${takeaway}, price_eatin: ${eatin}, price_active: ${active}, price_account: ${account}, price_group:${group}) {
        id
        product {
            id
            product_name
            account_product_name {
                id
                except_p_account
                except_p_row
                except_p_type
                except_p_data
                created_at
                updated_at
            }
            product_price {
                id
                product {
                    id
                    product_name
                    account_product_price {
                        id
                        except_c_account
                        except_c_row
                        except_c_value
                        except_c_takeaway
                        except_c_eatin
                        created_at
                        updated_at
                    }
                    product_detail {
                        id
                        detail_type
                        detail_text
                    }
                    product_menu {
                        id
                        menu_type
                        menu_name
                        menu_price
                    }
                    product_number
                    img_url
                }
                price_value
                price_takeaway
                price_eatin
                price_active
            }
            product_number
            img_url
        }
        price_value
        price_takeaway
        price_eatin
        price_active
    }
`;
export const UPDATE_PRODUCT_PRICE = (id, account, product, value, takeaway, eatin, active, price_account) => `
  update_product_price (id: ${id}, account: ${account}, price_product: ${product}, price_value: ${value}, price_takeaway: ${takeaway}, price_eatin: ${eatin}, price_active: ${active}, price_account: ${price_account}) {
        id
        product {
            id
            product_name
            account_product_name {
                id
                except_p_account
                except_p_row
                except_p_type
                except_p_data
                created_at
                updated_at
            }
            product_price {
                id
                product {
                    id
                    product_name
                    account_product_price {
                        id
                        except_c_account
                        except_c_row
                        except_c_value
                        except_c_takeaway
                        except_c_eatin
                        created_at
                        updated_at
                    }
                    product_detail {
                        id
                        detail_type
                        detail_text
                    }
                    product_menu {
                        id
                        menu_type
                        menu_name
                        menu_price
                    }
                    product_number
                    img_url
                }
                price_value
                price_takeaway
                price_eatin
                price_active
            }
            product_number
            img_url
        }
        price_value
        price_takeaway
        price_eatin
        price_active
    }`;

//Product Detail
export const ADD_PRODUCT_DETAIL = (detail_product, detail_type, detail_text, detail_account) => `add_product_detail (detail_product: ${detail_product}, detail_type: ${detail_type}, detail_text: "${detail_text}", detail_account: ${detail_account}) {
        id
        product {
            id

            product_name
            account_product_name {
                id
                except_p_account
                except_p_row
                except_p_type
                except_p_data
                created_at
                updated_at
            }
            product_price {
                id
                product {
                    id
                    product_name
                    account_product_price {
                        id
                        except_c_account
                        except_c_row
                        except_c_value
                        except_c_takeaway
                        except_c_eatin
                        created_at
                        updated_at
                    }
                    product_detail {
                        id
                        detail_type
                        detail_text
                    }
                    product_menu {
                        id
                        menu_type
                        menu_name
                        menu_price
                    }
                    product_number
                    img_url
                }
                price_value
                price_takeaway
                price_eatin
                price_active
            }
            product_number
            img_url
        }
        detail_type
        detail_text
    }`;
export const UPDATE_PRODUCT_DETAIL = (id, account, detail_product, detail_type, detail_text, detail_account) => `
update_product_detail (id: ${id}, account: ${account}, detail_product: ${detail_product}, detail_type: ${detail_type}, detail_text: "${detail_text}", detail_account: ${detail_account}) {
        id
        product {
            id
            product_name
            account_product_name {
                id
                except_p_account
                except_p_row
                except_p_type
                except_p_data
                created_at
                updated_at
            }
            product_price {
                id
                product {
                    id
                    product_name
                    account_product_price {
                        id
                        except_c_account
                        except_c_row
                        except_c_value
                        except_c_takeaway
                        except_c_eatin
                        created_at
                        updated_at
                    }
                    product_detail {
                        id
                        detail_type
                        detail_text
                    }
                    product_menu {
                        id
                        menu_type
                        menu_name
                        menu_price
                    }
                    product_number
                    img_url
                }
                price_value
                price_takeaway
                price_eatin
                price_active
            }
            product_number
            img_url
        }
        detail_type
        detail_text
    }`;
export const DELETE_PRODUCT_DETAIL = (id, account) => `
     delete_product_detail (id: ${id}, account: ${account}) {
        success, message
    }`;

//Except Product
export const CLOSE_UNTIL = (account, pId, pName, pType, Until) => `add_except_product(except_p_account:${account} except_p_type:${pType} except_p_row:${pId} except_p_not_show:"${Until}" except_p_data:"${pName}"){
  id
  except_p_row
  except_p_type
  except_p_data
  except_p_account
  except_p_not_show
}`;
export const CLOSE_UNTIL_UPDATE = (id, account, Until) => `update_except_product(id:${id} except_p_account:${account} except_p_not_show:"${Until}"){
   id
  except_p_row
  except_p_type
  except_p_data
  except_p_account
  except_p_not_show
}`;

//Product Menu
export const ADD_PRODUCT_MENU = (menu_type, name, price, product, account, sort) => `
 add_product_menu (menu_type: ${menu_type}, menu_name: "${name}", menu_price: ${price}, menu_row: ${product}, menu_account: ${account}, menu_sort:${sort}) {
        id
        menu_type
        menu_name
        menu_price
    }`;
export const DELETE_PRODUCT_MENU_ITEM = (id, account) => `
    delete_product_menu_item (id: ${id}, account: ${account}) {
     success, message
    }`;
export const DELETE_PRODUCT_MENU = (id, account) => `
    delete_product_menu (id: ${id}, account: ${account}) {
        success, message
    }`;
export const ADD_PRODUCT_MENU_ITEM = (item_menu, item_type, item_row, item_price, item_account, sort) => `
    add_product_menu_item (item_menu: ${item_menu}, item_type: ${item_type}, item_row: ${item_row}, item_price: ${item_price}, item_account: ${item_account}, item_sort:${sort}) {
        id
    }`;
export const UPDATE_PRODUCT_MENU_ITEM = (id, account, item_menu, item_type, item_row, item_price, item_account) => `
update_product_menu_item (id: ${id}, account: ${account}, item_menu: ${item_menu}, item_type: ${item_type}, item_row: ${item_row}, item_price: ${item_price}, item_account: ${item_account}) {
        id
        product {
            id
            product_group{
                id
            }
            product_name
            account_product_name {
                id
                except_p_account
                except_p_row
                except_p_type
                except_p_data
                created_at
                updated_at
            }
            product_type {
                id
                type_name
                created_at
                updated_at
            }
            product_price {
                id
                product {
                    id
                    product_group{id}
                    product_name
                    account_product_price {
                        id
                        except_c_account
                        except_c_row
                        except_c_value
                        except_c_takeaway
                        except_c_eatin
                        created_at
                        updated_at
                    }
                    product_detail {
                        id
                        detail_type
                        detail_text
                    }
                    product_menu {
                        id
                        menu_type
                        menu_name
                        menu_price
                    }
                    product_number
                    img_url
                }
                price_value
                price_takeaway
                price_eatin
                price_active
                price_group
            }
            product_number
            img_url
        }
        item_price
    }`;
export const SORT_PRODUCT_MENU = (id, sort) => `update_product_menu(id:${id} menu_sort:${sort}){id menu_sort}`;
export const SORT_PRODUCT_MENU_ITEM = (id, sort) => `update_product_menu_item(id:${id} item_sort:${sort}){id item_sort}`;
//Price Group
export const ADD_PRICE_GROUP = (account, name, active) => `
add_price_group (price_group_account: ${account}, price_group_name: "${name}", price_group_active: ${active}) {
        id
        price_group_account
        price_group_name
        price_group_active
        created_at
        updated_at
        default
    }`;
export const UPDATE_PRICE_GROUP = (id, account, priceGroupAccount, name, active) => `
update_price_group (id: ${id}, account: ${account}, price_group_account: ${priceGroupAccount}, price_group_name: "${name}", price_group_active: ${active}) {
        id
        price_group_account
        price_group_name
        price_group_active
        created_at
        updated_at
        default
    }`;

//Account
export const CREATE_CONFIRMATION = (account, table) => `createConfirmation (account: ${account}, table: ${table}) {
        id
        created_at
        updated_at

        table
        code
        state
    }`;
export const ACCEPT_ORDER = (orderId) => `acceptOrder (orderId: ${orderId}) {
        id
        customer {
            id
            customer_name
            user {
                id
                name
            }
            customer_order_source
        }
        order_price
order_point_use
        ordertype {
            id
            type_name
            created_at
            updated_at
        }
        status {
            id
            statu_name
            created_at
            updated_at
        }
        orderpayrule {
            id
            payrule_name
            created_at
            updated_at
        }
        order_json
        order_receive_time
        order_table
        order_confirm {
            id
            created_at
            updated_at
            table
            code
            state
        }
        created_at
        updated_at
    }`;
export const COMPLETE_ORDER = (orderId) => `completeOrder (orderId: ${orderId}) {
        id
        customer {
            id
            customer_name
            user {
                id
                name
            }
            customer_order_source
        }
        order_price
order_point_use
        ordertype {
            id
            type_name
            created_at
            updated_at
        }
        order_source {
            id
            per_order_credit
            created_at
            updated_at
        }
        status {
            id
            statu_name
            created_at
            updated_at
        }
        orderpayrule {
            id
            payrule_name
            created_at
            updated_at
        }
        order_json
        order_receive_time
        order_table
        order_confirm {
            id
            created_at
            updated_at
            table
            code
            state
        }
        created_at
        updated_at
    }`;
export const CANCEL_ORDER = (orderId, reasonId) => `cancelOrder (orderId: ${orderId} reason_id:${reasonId}) {
        id
        customer {
            id
            customer_name
            user {
                id
                name
                email
            }
            customer_order_source
        }
        account {
            id
            account_title
            brand {
                id
                brand_name
                brand_banner
            }
            account_type
            account_opening
            account_closing
            account_center
            account_price_group
        }
        address {
            id
            address_name
            address_phone
            address_text
            address_direction
            address_location
            address_lat
            address_long
            address_row
        }
        order_price
order_point_use
        ordertype {
            id
            type_name
            created_at
            updated_at
        }
        status {
            id
            statu_name
            created_at
            updated_at
        }
        orderpayrule {
            id
            payrule_name
            created_at
            updated_at
        }
        order_json
        order_receive_time
        order_table
        order_confirm {
            id
            created_at
            updated_at
            account {
                id
                account_title
                account_type
                account_opening
                account_closing
                account_center
                account_price_group
            }
            table
            code
            state
        }
        created_at
        updated_at
    }`;

//Account Address
export const ADD_ADDRESS = (name, phone, country, city, town, district, neighborhood, text, direction, type, row, lat, long, location) => `createAddress(address_name:"${name}" address_phone:"${phone}" address_country:${country} address_city:${city} address_town:${town} address_district:${district} address_neighborhood:${neighborhood} address_text:"${text}" address_direction:"${direction}" address_type:${type} address_row:${row} address_lat:${lat} address_long:${long} address_location:${JSON.stringify(location)}){
  id
  address_name
  address_direction
}`;
export const UPDATE_MAP_COORDINATES = (id, lat, long, location) => `updateAddress(id:${id} address_lat:${lat} address_long:${long} address_location:${JSON.stringify(location)}){
  id
  address_name
  address_direction
}`;
export const UPDATE_ADDRESS = (id, phone, address, direction) => `updateAddress(id:${id} address_phone:"${phone}" address_text:"${address}" address_direction:"${direction}" ){
  id
  address_name
  address_text
  address_direction
  address_phone
}`;
export const UPDATE_FULL_ADDRESS = (id, city, town, district, neighborhood) => `updateAddress(id:${id} address_city:${city} address_town:${town} address_district:${district} address_neighborhood:${neighborhood}){

  id
  address_name
  address_text
  address_direction
  address_phone
}`;

// Room/Salon and table
export const ADD_ROOM = (room_name, room_active, room_account) => `add_room(
        room_name:"${room_name}",
        room_active:${room_active},
        room_account:${room_account}
        ){
            id
            room_name
            room_active
            room_tables{
                id
                room_table_name
                room_table_room{id room_name room_active}
                room_table_x
                room_table_y
                room_table_active
                room_table_order{id order_price
order_point_use}
                created_at
                updated_at
            }
    }`;
export const UPDATE_ROOM = (id, room_name, room_active, room_account) => `update_room(
        id:${id},
        room_name:"${room_name}",
        room_active:${room_active},
        room_account:${room_account}
        ){
            id
            room_name
            room_active
            room_tables{
                id
                room_table_name
                room_table_room{id room_name room_active}
                room_table_x
                room_table_y
                room_table_active
                room_table_order{id order_price
order_point_use}
                created_at
                updated_at
            }
    }`;
export const ADD_ROOM_TABLE = (room_table_name, room_table_room, room_account, room_table_x, room_table_y, room_table_active) => `add_room_table(
        room_table_name:"${room_table_name}",
        room_table_room:${room_table_room},
        room_account:${room_account},
        room_table_x:${room_table_x},
        room_table_y:${room_table_y},
        room_table_active: ${room_table_active}
        ){
            id
            room_table_name
            room_table_active
            room_table_x
            room_table_y
            }`;
export const UPDATE_ROOM_TABLE = (id, room_table_name, room_table_room, room_table_x, room_table_y, room_table_active) => `update_room_table(
        id:${id},
        room_table_name:"${room_table_name}",
        room_table_room:${room_table_room},
        room_table_x:${room_table_x},
        room_table_y:${room_table_y},
        room_table_active: ${room_table_active}
        ){
            id
            room_table_name
            room_table_active
            room_table_x
            room_table_y
            }`;

//SURVEY || ANKET
export const ADD_SURVEY_GROUP = (name, depend, account) => `add_survey_group(
  group_name:"${name}",
  group_depend:${depend},
  group_account:${account}){
  id
  group_name
  group_depend
  group_brand
  group_account
  surveys{id survey_title}
  created_at
  updated_at
}`;
export const UPDATE_SURVEY_GROUP = (id, name, depend, account) => `update_survey_group(id:${id}, group_name:"${name}", group_depend:${depend},  group_account:${account}){
  id
  group_name
  group_depend
  group_brand
  group_account
  surveys{id survey_title}
  created_at
  updated_at
}`;
export const ADD_SURVEY = (brand, account, group, title, description, active, start, end) => `add_survey(
  survey_brand:${brand}
  survey_account:${account}
  survey_group:${group}
  survey_title:"${title}"
  survey_description:"${description}"
  survey_active:${active}
  survey_start:"${start}"
  survey_end:"${end}"
){
  id
  survey_brand
  survey_account
  survey_title
  survey_description
  survey_active
  survey_start
  survey_end
  survey_questions{id}
  created_at
  updated_at
  }`;
export const ADD_QUESTION = (account, type, label, text, placeholder, error, description, min, max, survey) => `add_survey_question(
  account:${account}
  question_type:${type}
  question_label:"${label}"
  question_text:"${text}"
  question_p_holder:"${placeholder}"
  question_er_msg:"${error}"
  question_description:"${description}"
  question_min_length:${min}
  question_max_length:${max}
  question_survey:${survey}
){
  id
  question_type{id name active}
  question_label
  question_text
  question_p_holder
  question_er_msg
  question_description
  question_min_length
  question_max_length
  question_survey
  question_items{
    id
    q_item_question
    q_item_value
    q_item_point
  }
 }`;
export const UPDATE_QUESTION = (id, account, type, label, text, placeholder, error, description, min, max, survey) => `update_survey_question(
  id:${id}
  account:${account}
  question_type:${type}
  question_label:"${label}"
  question_text:"${text}"
  question_p_holder:"${placeholder}"
  question_er_msg:"${error}"
  question_description:"${description}"
  question_min_length:${min}
  question_max_length:${max}
  question_survey:${survey}
){
  id
  question_type{id name active}
  question_label
  question_text
  question_p_holder
  question_er_msg
  question_description
  question_min_length
  question_max_length
  question_survey
  question_items{
    id
    q_item_question
    q_item_value
    q_item_point
  }
 }`;
export const DELETE_QUESTION = (id, account) => `delete_survey_question(id:${id} account:${account}){success message}`;
export const ADD_QUESTION_ITEM = (account, question, value, point) => `add_survey_item(account:${account} q_item_question:${question} q_item_value:"${value}" q_item_point:${point}){
  id
  q_item_question
  q_item_value
  q_item_point
  created_at
  updated_at
}`;
export const UPDATE_SURVEY = (id, group, title, description, active, start, end) => `update_survey(
  id:${id},
  survey_group:${group},
  survey_title:"${title}",
  survey_description:"${description}",
  survey_active:${active},
  survey_start:"${start}"
  survey_end:"${end}"
){
  id
  survey_brand
  survey_account
  survey_group
  survey_title
  survey_description
  survey_active
  survey_start
  survey_end
}`;
export const DELETE_SURVEY = (id, account) => `delete_survey(id:${id}, account:${account}){success message}`;

//FAST SELL
export const POST_QR_ORDER = (confAccount, totalAmount, orderNote, confTable, confId, confCode, cart) => `postWebQrOrder(order:{
                                account:${confAccount}
                                order_price:${totalAmount}
                                center:4
                                order_note:"${orderNote}"
                                order_table:${confTable}
                                order_confirm_id:${confId}
                                order_confirm_code:"${confCode}"
                                order_json:${JSON.stringify(cart).replace(/"([^(")]+)":/g, "$1:")}
                              }){id}`;

//ADD ACCOUNT PAYRULE
export const ADD_PAYRULE = (account, payrule, delivery, eatin, takeaway) => `add_account_payrule(payrule_id:${payrule} account:${account} payrule_eatin:${eatin} payrule_delivery:${delivery} payrule_takeaway:${takeaway}){
  id
  payrule_eatin
  payrule_delivery
  payrule_takeaway
  orderpayrule{id payrule_name payrule_type}
  created_at
  updated_at
}`;
export const UPDATE_PAYRULE = (account, payrule, delivery, eatin, takeaway) => `update_account_payrule(id:${payrule} account:${account} payrule_eatin:${eatin} payrule_delivery:${delivery} payrule_takeaway:${takeaway}){
  id
  payrule_eatin
  payrule_delivery
  payrule_takeaway
  orderpayrule{id payrule_name payrule_type}
  created_at
  updated_at
}`;
export const REMOVE_PAYRULE = (id) => `delete_account_payrule (id: ${id}) {
        success
        message
    }`;

//ADD NEIGHBOURHOOD
export const ADD_NEIGHBOURHOOD = (district, name, zipcode) => `add_neighbourhood(district_id: ${district} name:"${name}" zipcode:"${zipcode}") {
    id
    name
    zipcode
}`;
export const REMOVE_NEIGHBOURHOOD = (id) => `delete_neighbourhood(id: ${id}) {
    id
}`;
export const UPDATE_NEIGHBOURHOOD = (id, district, name, zipcode) => `update_neighbourhood(id: ${id} district: ${district} name: "${name}" zipcode: "${zipcode}") {
    id
    name
    zipcode
}`;

//UPDATE ACCOUNT
export const TOGGLE_RESTAURANT = (id, delivery, takeaway, eatin) => `update_account (id: ${id}, account_delivery: ${delivery}, account_takeaway: ${takeaway}, account_eatin: ${eatin}) {
        id
        account_title
        account_type
        account_opening
        account_closing
        account_center
        account_delivery
        account_takeaway
        account_eatin
        account_price_group
        account_e_mail
        account_kep
        account_tax_no
    }`;
export const UPDATE_RESTAURANT_EMAIL = (id, email) => `update_account (id: ${id}, account_e_mail: "${email}") {
        id
        account_title
        account_type
        account_opening
        account_closing
        account_center
        account_delivery
        account_takeaway
        account_eatin
        account_price_group
        account_e_mail
        account_kep
        account_tax_no
    }`;
export const UPDATE_RESTAURANT_ACTIVE = (id, isActive) => `update_account (id: ${id}, account_active: ${isActive}) {
        id
        account_active
        account_title
        account_type
        account_opening
        account_closing
        account_center
        account_delivery
        account_takeaway
        account_eatin
        account_price_group
        account_e_mail
        account_kep
        account_tax_no
    }`;

export const UPDATE_RESTAURANT_KEP = (id, kep) => `update_account (id: ${id}, account_kep: "${kep}") {
        id
        account_title
        account_type
        account_opening
        account_closing
        account_center
        account_delivery
        account_takeaway
        account_eatin
        account_price_group
        account_e_mail
        account_kep
        account_tax_no
    }`;
export const UPDATE_RESTAURANT_GSM = (id, gsm) => `update_account (id: ${id}, account_gsm_no: "${gsm}") {
        id
        account_title
        account_type
        account_opening
        account_closing
        account_center
        account_delivery
        account_takeaway
        account_eatin
        account_price_group
        account_e_mail
        account_kep
        account_tax_no
    }`;

export const UPDATE_RESTAURANT_TAX_NO = (id, taxNo) => `update_account (id: ${id}, account_tax_no: "${taxNo}") {
        id
        account_title
        account_type
        account_opening
        account_closing
        account_center
        account_delivery
        account_takeaway
        account_eatin
        account_price_group
        account_e_mail
        account_kep
        account_tax_no
    }`;
export const UPDATE_RESTAURANT_OPENING = (id, opening) => `update_account (id: ${id}, account_opening: "${opening}") {
        id
        account_title
        account_type
        account_opening
        account_closing
        account_center
        account_delivery
        account_takeaway
        account_eatin
        account_price_group
        account_e_mail
        account_kep
        account_tax_no
    }`;
export const UPDATE_RESTAURANT_CLOSING = (id, closing) => `update_account (id: ${id}, account_closing: "${closing}") {
        id
        account_title
        account_type
        account_opening
        account_closing
        account_center
        account_delivery
        account_takeaway
        account_eatin
        account_price_group
        account_e_mail
        account_kep
        account_tax_no
    }`;

//DELIVERY POINT THINGS
export const UPDATE_DELIVERY_POINT = (id, account, pointFee, pointMinPay, pointTime) => `update_delivery_point_neighborhood (id: ${id}, account: ${account}, point_fee: ${pointFee}, point_min_pay: ${pointMinPay}, point_time: ${pointTime}) {
        id
        point_type
        point_fee
        point_min_pay
        point_time
    }`;
export const ADD_DELIVERY_CITY = (row, account, pointFee, pointMinPay, pointTime) => `add_delivery_point_city (point_row: ${row}, point_fee: ${pointFee}, point_account: ${account}, point_min_pay: ${pointMinPay}, point_time: ${pointTime}) {
        id
        point_type
        point_fee
        point_min_pay
        point_time
    }`;
export const ADD_DELIVERY_NEIGHBORHOOD = (row, account, pointFee, pointMinPay, pointTime) => `add_delivery_point_neighborhood (point_row: ${row}, point_fee: ${pointFee}, point_account: ${account}, point_min_pay: ${pointMinPay}, point_time: ${pointTime}) {
        id
        point_type
        point_fee
        point_min_pay
        point_time
    }`;
export const DELETE_DELIVERY_POINT = (id, account) => `delete_delivery_point (id: ${id}, account: ${account}) {
        success
        message
    }`;

//UPDATE ACCOUNT WORK HOURS
export const UPDATE_WORK_HOURS = (id, account, day, opening, closing) => `update_account_work_hour(id:${id} work_hour_account:${account}
${day}_opening:"${opening}" ${day}_closing:"${closing}"){
  id
}`;
export const ADD_WORK_HOURS = (account) => `add_account_work_hour(work_hour_account:${account} ){id}`;
export const UPDATE_WORK_HOURS_ALL = (id, account) => `update_account_work_hour(
  id:${id}
  work_hour_account:${account}
  monday_opening:"10:00"
  monday_closing:"23:59"
  tuesday_opening:"10:00"
  tuesday_closing:"23:59"
  wednesday_opening:"10:00"
  wednesday_closing:"23:59"
  thursday_opening:"10:00"
  thursday_closing:"23:59"
  friday_opening:"10:00"
  friday_closing:"23:59"
  saturday_opening:"10:00"
  saturday_closing:"23:59"
  sunday_opening:"10:00"
  sunday_closing:"23:59"
  ){id}`;
//NEAR PLACES
export const ADD_NEAR_PLACE = (account, place) => `add_account_near_place(account:${account} place:${place}){
  id
  place{
    id
    address_text
    address_direction
    address_name
    address_lat
    address_type{id type_name}
    town{id name}
    citiy{id name}
    district{id name}
    neighborhood{id name}
    address_long
    address_location
  }
}`;
export const DELETE_NEAR_PLACE = (account, place) => `delete_account_near_place(id:${place} account:${account}){success message}`;

//DOCUMENTS
export const ADD_DOCUMENT = (account, url, type) => `add_document(document_account:${account} document_url:"${url}" document_type:${type}){
  id
  document_type{id document_type_name}
  document_url
}`;

