import {action, makeObservable, observable, runInAction} from "mobx";
import axios from "axios";
import {GET_BRANDS} from "../graphql/Queries";
const LANG=process.env.REACT_APP_LANG;
const API_BASE=(LANG==='tr')?(process.env.REACT_APP_API_BASE_TR):(process.env.REACT_APP_API_BASE_EN);
class BrandStore {
  loading=false;
  brands=[];
  constructor() {
    makeObservable(this, {
      loading: observable,
      brands: observable,
      getBrands: action
    })
  }

  async getBrands() {
    try {
      runInAction(()=> {this.loading = true;});
      const {data} = await axios({
        url: `${API_BASE}`,
        method: 'post',
        data: {
          query: `{${GET_BRANDS}}`
        }
      });
      runInAction(()=> {this.loading = false;});
      if (data.errors) {
        alert("Error %&/");
        return false;
      }
      runInAction(()=>{
        this.brands = data.data.brands;
        runInAction(()=> {this.loading = false;});
      });
    } catch (e) {
      console.log(e);
      runInAction(()=> {this.loading = false;});
      alert("Error %&/");
    }
  }
}
export default new BrandStore();
