import LayoutStore from "./LayoutStore";
import BrandStore from "./BrandStore";
import AuthStore from "./AuthStore";
import UserStore from "./UserStore";
import ProductGroupStore from "./ProductGroupStore";
import ProductStore from "./ProductStore";
import ToasterStore from "./ToasterStore";
import PriceGroupStore from "./PriceGroupStore";
import CDNStore from "./CDNStore";
import AccountStore from "./AccountStore";
import DataStore from "./DataStore";
import AddressStore from "./AddressStore";
import DocumentStore from "./DocumentStore";
import CreditStore from "./CreditStore";
import TicketStore from "./TicketStore";
import RegistrationStore from "./RegistrationStore";
import ExcelStore from "./ExcelStore";

export default {
  AuthStore,
  LayoutStore,
  BrandStore,
  AccountStore,
  UserStore,
  ProductGroupStore,
  PriceGroupStore,
  ProductStore,
  ToasterStore,
  CDNStore,
  DocumentStore,
  DataStore,
  AddressStore,
  CreditStore,
  RegistrationStore,
  TicketStore,
  ExcelStore
}
