import {action, makeObservable, observable, runInAction} from "mobx";

class ToasterStore {
  loading=false;
  message={type:"success", body:"Toaster, it is..."};
  messages=[];

  constructor() {
    makeObservable(this,{
      loading: observable,
      messages: observable,
      pushMessage: action
    });
  }

  pushMessage(message){
    runInAction(()=>this.messages.push(message));
  }
}

export default new ToasterStore();
